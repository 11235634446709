import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import appVars from '../../../appVars.json';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Card, CardHeader, CardContent, Grid, LinearProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
    },
    chip: {
        margin: 2,
        overflow: 'hidden'
    },
}));

const GoogleAccounts = (props) => {
    const classes = useStyles();
    const [selectedOptions, setSelectedOptions] = useState(() => {
        // Check if defaultValue is a string and contains commas (CSV string)
        if (typeof props.defaultValue === 'string' && props.defaultValue.includes(',')) {
            return props.defaultValue.split(',').map(value => value.trim());
        }
        // Check if defaultValue is defined and not an empty string, then wrap in an array
        // This will handle single numbers, single strings without commas, and non-empty strings
        else if (props.defaultValue || typeof props.defaultValue === 'number') {
            return [`${props.defaultValue}`]; // Convert to string to ensure consistency
        }
        // Return an empty array if defaultValue is undefined, null, or empty
        return [];
    });


    const [options, setOptions] = useState();
    const [adoptions, setAdOptions] = useState();
    const [token, setToken] = useState([props.token]);



    async function fetchData() {
        let url = appVars.api_url;

        if (url === "") {
            return false;
        }

        try {

            const r = await fetch(url += "/google/api/tokens", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                redirect: 'follow'
            })
            const rData = await r.json();
            setAdOptions(rData);


            if (props.token !== '') {
                let type = '';
                let url = appVars.api_url;
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    body: JSON.stringify({ token: props.token })
                };

                switch (props.name) {
                    case 'google_paid':
                        url += "/google/accountids/get";
                        type = 'Paid';
                        break;
                    case 'google_grant':
                        url += "/google/accountids/get";
                        type = 'Grant';
                        break;
                    default:
                        url = ""
                        break;
                }


                const response = await fetch(url, requestOptions);

                const data = await response.json();
                const filteredData = type ? _.filter(data, (row) => _.includes(row.ACCOUNT_NAME, type)) : data;
             //   console.log(data);
                setOptions(filteredData);

            }




        } catch (error) {
            console.error('error', error);
        }
    }

    useEffect(() => {
        if (!adoptions) {
            fetchData();
        }
        
        // eslint-disable-next-line
    }, [props, options]);

    return (
        <div className={classes.formControl}>

            <Card >

                <CardContent>

                    <Grid container spacing={2}>
                        {(adoptions ?


                            <Grid item sm={12}>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    disabled={(props.admintoken >= 6 ? false : true)}
                                    options={adoptions}
                                    getOptionLabel={(option) => `${option.name} - ${option.token}`}
                                    defaultValue={token.map((option) => {
                                          console.log('Passed Token',option[0])
                                        var a = _.find(adoptions, { 'token': option[0] });
                                        console.log('Found Token',a)
                                        return (
                                            a
                                        )
                                    })}
                                    data-tab={props.tab}
                                    onChange={async (e, newValue) => {
                                        console.log(newValue[0].token);
                                        setToken(newValue[0].token);

                                        let type = '';
                                        let url = appVars.api_url;
                                        const requestOptions = {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            redirect: 'follow',
                                            body: JSON.stringify({ token: newValue[0].token })
                                        };

                                        switch (props.name) {
                                            case 'google_paid':
                                                url += "/google/accountids/get";
                                                type = 'Paid';
                                                break;
                                            case 'google_grant':
                                                url += "/google/accountids/get";
                                                type = 'Grant';
                                                break;
                                            default:
                                                url = ""
                                                break;
                                        }




                                        const response = await fetch(url, requestOptions);

                                        const data = await response.json();
                                        const filteredData = type ? _.filter(data, (row) => _.includes(row.ACCOUNT_NAME, type)) : data;

                                        setOptions(filteredData);




                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip data-tab={props.tab} label={option.name} {...getTagProps({ index })} className={classes.chip} style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }} key={option.token} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            data-tab={props.tab}
                                            {...params}
                                            variant="outlined"
                                            label={`Select ${props.label} Customer Account`}

                                        />
                                    )}
                                />   </Grid>
                            : <LinearProgress></LinearProgress>
                        )}

                        <Grid item sm={12}>
                            {(options ?
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    disabled={(props.admintoken >= 6 ? false : true)}
                                    options={options}
                                    getOptionLabel={(option) => `${option.ACCOUNT_NAME} - ${option.ACCOUNT_ID}`}
                                    defaultValue={selectedOptions.map((option) => {
                                        console.log(option)
                                        var a = _.find(options,function(o) { 
                                            return o.ACCOUNT_ID.toString() === option;
                                         });
                                         console.log((a ? a: ''))
                                        return (
                                            a
                                        )
                                    })}
                                    data-tab={props.tab}
                                    onChange={(e, newValue) => {
                                        console.log(newValue);
                                        const updatedArray = newValue.filter(item => item.id !== 0);
                                 

                                        const reportReturn = Object.keys(updatedArray).map((key, index) => (
                                            updatedArray[index].ACCOUNT_ID
                                        ));
                                        e.stopPropagation();
                                        setSelectedOptions(reportReturn);
                                        if (props.onSelectionChange) {
                                            props.onSelectionChange(reportReturn, props.tab, props.label, token[0]);
                                        }
                                        console.log(props.tab);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => {
                                            console.log(option);
                                            return(
                                            (option ? <Chip data-tab={props.tab} label={option.ACCOUNT_NAME} {...getTagProps({ index })} className={classes.chip} style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }} key={option.ACCOUNT_ID} />: '')
                                        )})
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            data-tab={props.tab}
                                            {...params}
                                            variant="outlined"
                                            label={`Select ${props.label}`}

                                        />
                                    )}
                                />
                                : <Typography>Select Customer Account</Typography>
                            )}
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default GoogleAccounts;
