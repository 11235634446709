import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Badge, Divider, Link, useTheme, ListItemIcon, Box, LinearProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import LightBoxSettings from './LightBoxSettings';
import moment from 'moment';
import appVars from "../appVars.json";
import { CloudDownload, DeleteOutline, Edit, FileCopy } from '@material-ui/icons';
import _ from 'lodash';
import { lightenHexColor } from '../dwi-functions';
import { useToken } from '../Login/TokenProvider';
import CurrencyFormat from 'react-currency-format';
import { NumericFormat } from 'react-number-format';

// eslint-disable-next-line
const countdown = require('moment-countdown');

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    padding: theme.spacing(3)
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  lightTitle: {
    fontSize: '1rem'
  },
  root: {
    background: '#fff'
  },
  container: {
    padding: '1em',
  },
  media: {
    height: 255,
    backgroundPosition: 'top center'
  },
  cardHeader: {
    color: "#fff",
    background: theme.palette.primary.main,
    padding: theme.spacing(2)
  },
  menu_header: {
    padding: '5px',
    backgroundColor: theme.palette.primary.main,
    color: "#fff"
  },
  h5: {
    padding: '15px',
    color: theme.palette.primary.light
  },
  button: {
    borderStyle: 'solid',
    border: '1px',
    borderColor: '#fff',
    color: '#fff',
    marginRight: "7px"
  },
  text: {
    width: "100%",
  },
  dense: {
    float: "right",
  },
  text30: {
    width: "48%",
  },
  image: {
    maxWidth: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3)
  },
  drop: {
    width: '50%',
    marginLeft: '0',
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    display: 'none'
  },
  padding: {
    width: '100%',
    marginRight: theme.spacing(3)
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LightCard = (props) => {
  const classes = useStyles();
  const im = props.image;
  const [anchorEl, setAnchorEl] = useState(null);
  const [stats, setStats] = useState(false);
  const [convStats, setConvStats] = useState(false);
  const [openLBE, setOpenLBE] = useState(false);
  const [timer, setTimer] = useState(moment(props.endTime).countdown().toString());
  const theme = useTheme();
  const token = useToken();
  const admintoken = token.token;
  const intervalRef = useRef();

  useEffect(() => {
    const fetchStats = async () => {
      const a = new Date(props.startTime);
      const b = new Date(props.endTime);
      const data = {
        module: 'API',
        format: 'JSON',
        idSite: props.beaconId,
        period: 'range',
        date: `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()},${b.getFullYear()}-${b.getMonth() + 1}-${b.getDate()}`,
        method: 'Events.getName',
        expanded: 1,
        pivotBy: 'Events.EventAction',
        pivotByColumnLimit: 20,
        pivotByColumn: 'nb_events',
        token_auth: appVars.matomo_token,
        filter_limit: 50
      };



      const RevenueData = {
        module: 'API',
        format: 'JSON',
        idSite: props.beaconId,
        period: 'range',
        date: `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()},${b.getFullYear()}-${b.getMonth() + 1
          }-${b.getDate()}`,
        method: 'Goals.get',
        segment: "eventAction==clicked;eventName==" + props.lightboxId,
        IdGoal: "ecommerceOrder",
        token_auth: appVars.matomo_token,
      };


      const res = await fetch(appVars.matomo_url + '/index.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(data).toString()
      });

      console.log('st',props);
                    


      const res2 = await fetch(`${appVars.matomo_url}/index.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(RevenueData).toString(),
      });

      const result2 = await res2.json();
      setConvStats(result2);




      const result = await res.json();
      if (result.length > 0) {
        const k = _.find(result, o => o.label === props.lightboxId);
        const st = {
          label: result[0].label,
          impressions: Object.values(k)[1],
          closed: Object.values(k)[2],
          clicked: Object.values(k)[3]
        };
        setStats(st);
      }
    };

    fetchStats();
    intervalRef.current = setInterval(() => {
      const a = moment().countdown(props.endTime);
      setTimer(a.toString());
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [props]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenLBE(false);
  };

  const handleClickOpenLBE = () => {
    setOpenLBE(true);
  };

  return (
    <Grid item xl={3} md={4} sm={2} xs={12} key={props.lightboxId}>
      <Card className={classes.root} key={props.lightboxId} id={props.lightboxId}>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon style={{ color: "#fff" }} />
            </IconButton>
          }
          title={props.description}
          className={classes.cardHeader}
          disableTypography={true}
        />
        <Menu
          id={props.lightboxId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {admintoken.token >= 10 && [
            <MenuItem key="settings" onClick={handleClickOpenLBE}>
              <ListItemIcon><Edit /></ListItemIcon>Settings
            </MenuItem>,
            <MenuItem key="copy" onClick={(e) => {
              const myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              const data = { ...props, lightboxId: "NEW", pkRecId: 0, embed: JSON.stringify(props.embed) };
              const raw = JSON.stringify(data);

              fetch(appVars.api_url + "/lightbox/add", {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              })
                .then(response => response.text())
                .then(() => window.location.reload())
                .catch(error => console.log('error', error));
            }}>
              <ListItemIcon><FileCopy /></ListItemIcon>Copy
            </MenuItem>,
            <MenuItem key="download">
              <ListItemIcon><CloudDownload /></ListItemIcon>
              <Link color="textPrimary" title={`Download Google Tag Manager tag for Lightbox ${props.description}`} target='_blank' href={`${appVars.api_url}/generate-gtm-lightbox/${props.lightboxId}`}>Download</Link>
            </MenuItem>,
            <MenuItem key="delete" onClick={(e) => {
              fetch(`${appVars.api_url}/lightbox/delete/${props.lightboxId}`, {
                method: 'GET',
                redirect: 'follow'
              })
                .then(response => response.text())
                .then(() => window.location.reload())
                .catch(error => console.log('error', error));
            }}>
              <ListItemIcon><DeleteOutline /></ListItemIcon>Delete
            </MenuItem>
          ]}
          <Divider />
          <MenuItem key="previewA">
            <Link color="textPrimary" href={appVars.lightwerksUrl + `lightbox/preview/${btoa(`${props.lightboxId}|${props.clientId}`)}`} target="_BLANK">Preview Lightbox (A)</Link>
          </MenuItem>
          <MenuItem key="previewB">
            <Link color="textPrimary" href={appVars.lightwerksUrl + `lightbox/previewb/${btoa(`${props.lightboxId}|${props.clientId}`)}`} target="_BLANK">Preview Lightbox (B)</Link>
          </MenuItem>
          <MenuItem key="previewHTML">
            <Link color="textPrimary" href={appVars.lightwerksUrl + `lightbox/preview-html/${btoa(`${props.lightboxId}|${props.clientId}`)}`} target="_BLANK">Preview Lightbox (HTML)</Link>
          </MenuItem>
        </Menu>

        <CardActionArea onClick={handleClickOpenLBE}>
          <CardMedia
            className={classes.media}
            image={im.imageSrc}
            title={im.imageAlt}
            style={{ filter: (!props.status ? 'grayscale(100%)' : '') }}
          />
        </CardActionArea>
        <CardContent>
          {stats && (
            <Grid container spacing={1} style={{ margin: `${theme.spacing(2)} ${theme.spacing(0)}` }}>
              <Grid item sm={6}>
                <Box p={1} bgcolor={theme.palette.primary.main} border={1} borderColor={"#e4e4e4"}>
                  <Typography align='center'>Impressions:<br />{stats.impressions}</Typography>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Badge anchorOrigin={{
                  vertical: "right",
                  horizontal: 'left',
                }} className={classes.padding} color="primary" badgeContent={(stats.impressions === 0 ? 0 : ((stats.clicked / stats.impressions) * 100).toFixed(2)) + "%"}>
                  <Box width="100%" p={1} bgcolor={theme.palette.primary.main} border={1} borderColor={"#e4e4e4"}>
                    <Typography align='center'>
                      Clicked:<br />{stats.clicked}
                    </Typography>
                  </Box>
                </Badge>
              </Grid>
            </Grid>
          )}






          {(convStats ?


            <Grid container spacing={1} style={{ margin: `${theme.spacing(2)} ${theme.spacing(0)}` }}>
              <Grid item sm={12}>
                <Box textAlign="center" display="flex" gridGap={theme.spacing(1)} >
                  <Box width="50%" p={1} bgcolor={props.themeColor.primary} border={1} borderColor={"#e4e4e4"}>
                    <Typography align="center">Conversions (<NumericFormat value={convStats.nb_conversions} thousandSeparator="," displayType="text" ></NumericFormat>) </Typography><NumericFormat style={{ color: "blue" }} value={convStats.nb_visits_converted_new_visit} thousandSeparator="," displayType="text" ></NumericFormat>|<NumericFormat style={{ color: "green" }} value={convStats.nb_conversions_returning_visit} thousandSeparator="," displayType="text" ></NumericFormat>
                  </Box>
                  <Box width="50%" p={1} bgcolor={theme.palette.primary.main} border={1} borderColor={"#e4e4e4"}>
                    <Typography align="center">Rate (<NumericFormat value={convStats.conversion_rate} suffix='%' fixedDecimalScale={true} decimalScale={2} displayType="text" ></NumericFormat>)</Typography><NumericFormat style={{ color: "blue" }} value={convStats.conversion_rate_new_visit} suffix='%' fixedDecimalScale={true} decimalScale={2} displayType="text" ></NumericFormat> | <NumericFormat style={{ color: "green" }} value={convStats.conversion_rate_returning_visit} suffix='%' fixedDecimalScale={true} decimalScale={2} displayType="text" ></NumericFormat>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12}>

              <Box textAlign="center" width="100%" p={1} bgcolor={props.themeColor.primary} border={1} borderColor={"#e4e4e4"}>
                  <Typography align="center">Revenue (<CurrencyFormat fixedDecimalScale={true} value={convStats.revenue} thousandSeparator="," prefix='$' decimalScale={2} displayType="text" ></CurrencyFormat>)</Typography> <CurrencyFormat style={{ color: "blue" }} fixedDecimalScale={true} value={convStats.revenue_new_visit} thousandSeparator="," prefix='$' decimalScale={2} displayType="text" ></CurrencyFormat> | <CurrencyFormat style={{ color: "green" }} fixedDecimalScale={true} value={convStats.revenue_returning_visit} thousandSeparator="," prefix='$' decimalScale={2} displayType="text" ></CurrencyFormat>
                </Box>

              </Grid>
            </Grid>



            : <LinearProgress />)}




          <Grid container>
            <Grid item md={8} xs={12} sm={12} style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
              <Typography variant="body2" color="textSecondary" component="p">Start Time</Typography>
              <Typography variant="body2" color="textSecondary" component="p">{new Date(props.startTime).toLocaleDateString()} - {new Date(props.startTime).toLocaleTimeString()}</Typography>
            </Grid>

            <Grid item md={8} xs={12} sm={12} style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
              <Typography variant="body2" color="textSecondary" component="p">End Time</Typography>
              <Typography variant="body2" color="textSecondary" component="p">{new Date(props.endTime).toLocaleDateString()} - {new Date(props.endTime).toLocaleTimeString()}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ display: "none" }}>
          <Button size="small" style={{ width: "50%" }} variant="outlined" onClick={handleClickOpenLBE} color="secondary">Edit Settings</Button>
          <Button size="small" style={{ width: "50%" }} variant="outlined" color="primary" onClick={(e) => {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const data = { ...props, lightboxId: "NEW", pkRecId: 0, embed: JSON.stringify(props.embed) };
            const raw = JSON.stringify(data);

            fetch(appVars.api_url + "/lightbox/add", {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            })
              .then(response => response.text())
              .then(() => window.location.reload())
              .catch(error => console.log('error', error));
          }}>Duplicate</Button>
        </CardActions>
        <Dialog id="Dialog" fullScreen open={openLBE} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h4" className={classes.title} />
            </Toolbar>
          </AppBar>
          <LightBoxSettings {...props} />
        </Dialog>
      </Card>
    </Grid>
  );
};

LightCard.propTypes = {};

export default LightCard;
