import { AppBar, Button, ButtonGroup, Box, Card, CardContent, CardHeader, IconButton, Grid, Typography, LinearProgress, Tabs, Tab, CircularProgress, Divider } from '@material-ui/core';
import { React, useState, useEffect, useRef } from 'react';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { lightenHexColor, generatePDF } from '../../../../dwi-functions';
import moment from 'moment';
import appVars from '../../../../appVars.json';
import PieChartCard from '../../../pie-chart';
import _ from 'lodash';
import Comments from '../../../../system/control/comments';
import StackedBarChartAgeComponent from '../../../StackedBarChartAgeComponent';
import { FaWindowClose, FaImages } from "react-icons/fa";
import { MdOutlineShortText, MdOutlineTextSnippet, MdOutlineCallToAction } from "react-icons/md";
import MaterialTable from 'material-table';
import { FcPhoneAndroid, FcTabletAndroid, FcPrint, FcIpad, FcIphone } from "react-icons/fc";
import { FaDesktop } from "react-icons/fa";
import { MdDeviceUnknown } from "react-icons/md";

const fontSize = '13px';




const devices = {
    android_smartphone: <FcPhoneAndroid size={48} />,
    android_tablet: <FcTabletAndroid size={48} />,
    desktop: <FaDesktop size={48} />,
    ipad: <FcIpad size={48} />,
    iphone: <FcIphone size={48} />,
    ipod: <MdDeviceUnknown size={48} />,
    other: <MdDeviceUnknown size={48} />
}







function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0} >
                    {children}
                </Box>
            )}
        </div>
    );
}




function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const fetchImageUrl = async (imageName) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ "image": imageName }),
        redirect: "follow"
    };
    try {
        const response = await fetch(`${appVars.api_url}/meta/creatives/image`, requestOptions);
        const data = await response.json();
        //  console.log(data.IMAGE_ASSET_URL);
        return (data.IMAGE_ASSET_URL === undefined ? 'https://api.digitalwerks.com:7325/imageFiles/no-image-icon.png' : data.IMAGE_ASSET_URL);
    } catch (error) {
        console.error(error);
        return 'https://api.digitalwerks.com:7325/imageFiles/no-image-icon.png'; // Default image on error
    }
};



const ShowImage = ({ thename }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const theI = await fetchImageUrl(thename);
                setImageUrl(theI);
            } catch (error) {
                setError(true);
            }
        };

        fetchImage();
    }, [thename]);

    return (
        <img
            style={{ textAlign: 'left', maxWidth: 100, fontSize: 'initial', padding: 0, margin: 0 }}
            alt={thename}
            src={error ? 'https://api.digitalwerks.com:7325/imageFiles/no-image-icon.png' : imageUrl}
            onError={(event) => {
                event.target.src = 'https://api.digitalwerks.com:7325/imageFiles/no-image-icon.png';
            }}
        />
    );
};




const Meta = (props) => {
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [creativeData, setCreativeData] = useState();
    const [agePie, setAgePie] = useState();
    const [ageData, setAgeData] = useState();
    const [genderData, setGenderData] = useState();
    const [data, setMetaData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [devicesData, setDevicesData] = useState();
    const [adsetData, setAdsetData] = useState();
    const [admintoken, setAdmin] = useState(props.admintoken);
    const [imageUrls, setImageUrls] = useState({});
    const [imagesLoading, setImagesLoading] = useState(true);
    const [combinedCellWidth, setCombinedCellWidth] = useState(0);
    const actionCellRef = useRef(null);
    const firstCellRef = useRef(null);
    const pDate = new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.sDate)) + "-" + new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.eDate));
    const reportRef1 = useRef()
    const reportRef2 = useRef()
    const reportRef3 = useRef()
    const reportRef4 = useRef()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary,
            },
            secondary: {
                main: props.themeColor.secondary,
            },
        },
        overrides: {
            MuiTab: {

                // Target the background color of the selected tab
                root: {
                    '&$selected': {
                        color: props.themeColor.secondary, // Example: Deep orange color for the selected tab
                        backgroundColor: props.themeColor.primary // Example: Deep orange color for the selected tab
                    }
                }

            },
            MuiInputAdornment: {
                root: {
                    color: props.themeColor.secondary,
                },
            },
            MuiIcon: {
                root: {
                    color: lightenHexColor(props.themeColor.secondary, 25),
                },
            },
            MuiOutlinedInput: {
                root: {
                    padding: 0,
                    background: lightenHexColor(props.themeColor.secondary, 45),
                },
                input: {
                    padding: 7,
                },
            },
            MuiPaper: {
                elevation2: {
                    boxShadow: 'none',
                },
                root: {
                    border: '0px solid ' + lightenHexColor(props.themeColor.secondary, 25),
                },
            },
        },
    });




    const headerStyleObj = {
        textAlign: 'center',
        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
        color: lightenHexColor(props.themeColor.primary, 0),
        fontSize: fontSize,
        border: '1px solid ' + props.themeColor.secondary,
        paddingTop: 5,
        paddingBottom: 5,
        maxWidth: 75,
    };

    const classes = makeStyles((theme) => ({
        selected: {
            background: props.themeColor.secondary,
            color: '#000',
            fontSize: '101%',
        },
    }));

    async function fetchReportData() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            const res4 = await fetch(appVars.api_url + "/meta/data", requestOptions);
            const metaData = await res4.json();
            setMetaData(metaData);

            const res5 = await fetch(appVars.api_url + "/meta/devices", requestOptions);
            const devicesData = await res5.json();
            setDevicesData(devicesData);

            const campTable = await fetch(appVars.api_url + "/meta/campaigns", requestOptions);
            const campaigns = await campTable.json();
            setCampaignData(campaigns);

            const adsets = await fetch(appVars.api_url + "/meta/adsets", requestOptions);
            const adsetsData = await adsets.json();
            setAdsetData(adsetsData);

            const creativeTable = await fetch(appVars.api_url + "/meta/creatives", requestOptions);
            const creatives = await creativeTable.json();
            setCreativeData(creatives);

            const ageChart = await fetch(appVars.api_url + "/meta/data/age", requestOptions);
            const age = await ageChart.json();

            const transformedData = _(age)
                .groupBy('AGE')
                .map((value, key) => ({
                    ageRange: key,
                    female: _.sumBy(value, (o) => (o.GENDER === 'female' ? parseInt(o.CLICKS, 10) : 0)),
                    male: _.sumBy(value, (o) => (o.GENDER === 'male' ? parseInt(o.CLICKS, 10) : 0)),
                    unknown: _.sumBy(value, (o) => (o.GENDER === 'unknown' ? parseInt(o.CLICKS, 10) : 0)),
                }))
                .value();

            const sd = _(age)
                .groupBy('AGE')
                .map((groupedData, key) => ({ name: key, count: _.sumBy(groupedData, 'CLICKS') }))
                .value();

            const rd = _(age)
                .groupBy('GENDER')
                .map((groupedData, key) => ({ name: key, count: _.sumBy(groupedData, 'CLICKS') }))
                .value();

            setGenderData(rd);
            setAgeData(transformedData);
            setAgePie(sd);

            const imageUrls = await Promise.all(
                creatives[1][0].map(async (item) => {
                    const imageUrl = await fetchImageUrl(item.NAME);
                    return { [item.NAME]: imageUrl };
                })
            );
            setImageUrls(Object.assign({}, ...imageUrls));
            setImagesLoading(false);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        if (actionCellRef.current && firstCellRef.current) {
            setCombinedCellWidth(actionCellRef.current.offsetWidth + firstCellRef.current.offsetWidth);
        }

        fetchReportData();
    }, [props]);

    return (
        <div><ThemeProvider theme={theme}>
            {data ? (
                <div>
                    <AppBar variant="outlined" color='secondary' position="relative" style={{ margin: theme.spacing(0) }} >
                        <Grid container alignItems='center' >
                            <Grid item sm={10}>
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto">
                                    <Tab label="Campaign" classes={{ selected: classes.selected }} {...a11yProps(0)} />
                                    <Tab label="Audiences" classes={{ selected: classes.selected }} {...a11yProps(1)} />
                                    <Tab label="Creatives" classes={{ selected: classes.selected }} style={{ display: (admintoken.token >= 10 ? 'none' : 'none') }} {...a11yProps(2)} />
                                    <Tab label="Demographics" classes={{ selected: classes.selected }} {...a11yProps(3)} />
                                    <Tab label="Device Info" classes={{ selected: classes.selected }} {...a11yProps(4)} />
                                    <Tab label="Notes" classes={{ selected: classes.selected }} {...a11yProps(5)} />
                                </Tabs>
                            </Grid>
                            {(admintoken.token >= 6 || props.client.showCost === true ?
                                <>
                                    <Grid item sm={1}>
                                        <Box p={1} bgcolor={lightenHexColor(props.themeColor.secondary, 40)} mr={1} border={1} borderColor={lightenHexColor(props.themeColor.secondary, 10)} ><Typography color="textPrimary">Cost: <NumericFormat decimalScale={2} value={data.cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography></Box>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'initial' }}>
                                        <Box p={1} bgcolor={lightenHexColor(props.themeColor.secondary, 40)} mr={1} border={1} borderColor={lightenHexColor(props.themeColor.secondary, 10)} > <Typography color="textPrimary">ROAS: <NumericFormat decimalScale={2} value={(data.revenue.count / data.cost.count)} displayType="text" /></Typography></Box>
                                    </Grid>
                                </>
                            :'')}

                        </Grid>
                    </AppBar>

                    <TabPanel value={value} index={0}>

                        <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                            <ButtonGroup size="medium">
                                <Button variant="text" title='Print Report'
                                    onClick={() => generatePDF(reportRef1, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                ><FcPrint size={20} />
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <div ref={reportRef1} style={{ padding: theme.spacing(1) }}>
                            <Grid container spacing={1} >
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Impressions" explain={appVars.tooltips.impression} data={data.impressions} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Clicks" data={data.clicks} explain={appVars.tooltips.click} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="CTR" data={data.ctr} explain={appVars.tooltips.ctr} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="CPM" data={data.cpm} explain={appVars.tooltips.cpm} client={props.client} color={props.themeColor} />
                                </Grid>

                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Conversions" data={data.conversions} explain={appVars.tooltips.conversion} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Revenue" data={data.revenue} explain={appVars.tooltips.revenue} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Conversion Rate" data={data.convrate} explain={appVars.tooltips.conv_rate} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Avg Gift" data={data.avgGift} explain={appVars.tooltips.avg_gift} client={props.client} color={props.themeColor} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} alignContent="space-between">
                                <Grid item sm={12} xs={12}>
                                    {campaignData ? (
                                        <TableCard
                                            options={{
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                headerStyle: headerStyleObj,
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (campaignData.length > 10),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true,
                                            }}
                                            title=""
                                            data={campaignData}
                                            count={Math.min(campaignData.length, 10)}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            columns={[
                                                {
                                                    title: "Campaign",
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor("#cecece", 0),
                                                        textAlign: 'center',
                                                        width: 300,
                                                        fontSize: fontSize,
                                                        padding: theme.spacing(1),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    },
                                                    field: "CAMPAIGN", render: rowdata => <Typography >{rowdata.CAMPAIGN}</Typography>
                                                }
                                            ]}
                                        />
                                    ) : (
                                        <div>
                                            <Typography color='secondary' variant='h6' align='center'>Loading Campaign Data</Typography>
                                            <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={1} data-title="Audience">
                        <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                            <ButtonGroup size="medium">
                                <Button variant="text" title='Print Report'
                                    onClick={() => generatePDF(reportRef2, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                ><FcPrint size={20} />
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <div ref={reportRef2}>

                            <Grid container spacing={2} alignContent="space-between">
                                <Grid item sm={12} xs={12}>
                                    {creativeData ? (
                                        <MaterialTable
                                            title=""
                                            data={creativeData[0]}
                                            count={Math.min(creativeData[0].length, 10)}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            options={{
                                                headerStyle: headerStyleObj,
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: (creativeData.length > 10),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                { title: "Audience", cellStyle: { width: '100%', textAlign: 'left', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "CREATIVE", render: rowdata => <Typography>{rowdata.CREATIVE}</Typography> },
                                                {
                                                    title: 'Impressions',
                                                    cellStyle: {
                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    },
                                                    field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    },
                                                    title: 'Clicks',
                                                    field: 'CLICKS',
                                                    render: (rowdata) => (
                                                        <div style={{ display: 'flex' }}>
                                                            <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                            </Box>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    },
                                                    title: 'CTR',
                                                    field: 'CTR',
                                                    render: (rowdata) => (
                                                        <div style={{ display: 'flex' }}>
                                                            <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                            </Box>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    },
                                                    title: 'Conversions',
                                                    field: 'CONVERSIONS',
                                                    render: (rowdata) => (
                                                        <div style={{ display: 'flex' }}>
                                                            <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                            </Box>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    },
                                                    title: 'Conv %',
                                                    field: 'CONV_RATE',
                                                    render: (rowdata) => (
                                                        <div style={{ display: 'flex' }}>
                                                            <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                            </Box>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    title: 'Revenue',
                                                    cellStyle: {

                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                },
                                                {
                                                    title: 'Avg. Gift',
                                                    cellStyle: {

                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                },
                                                {
                                                    title: 'CPC',
                                                    cellStyle: {

                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                },
                                                {
                                                    title: 'CPM',
                                                    cellStyle: {

                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                },
                                                {
                                                    title: 'Cost',
                                                    cellStyle: {

                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                },
                                                {
                                                    title: 'ROAS',
                                                    cellStyle: {

                                                        textAlign: 'center',
                                                        padding: theme.spacing(0),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 6 || props.client.showCost ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                }
                                            ]}
                                            detailPanel={[
                                                {
                                                    tooltip: 'Show Images',
                                                    icon: () => <FaImages color={props.themeColor.secondary} size={20} />,
                                                    openIcon: () => <FaWindowClose color={props.themeColor.secondary} size={20} />,
                                                    render: rowData => (
                                                        <div style={{ marginLeft: combinedCellWidth }}>
                                                            {creativeData ? (
                                                                <MaterialTable
                                                                    title="Images"
                                                                    data={query =>
                                                                        new Promise(async (resolve, reject) => {
                                                                            var myHeaders = new Headers();
                                                                            myHeaders.append("Content-Type", "application/json");
                                                                            var raw = JSON.stringify({
                                                                                "agroup": rowData.ADGROUP,
                                                                                "sdate": props.sDate,
                                                                                "edate": props.eDate
                                                                            });
                                                                            var requestOptions = {
                                                                                method: 'POST',
                                                                                headers: myHeaders,
                                                                                body: raw,
                                                                                redirect: 'follow'
                                                                            };
                                                                            const res = await fetch(appVars.api_url + "/meta/creatives/adgroup", requestOptions);
                                                                            const creatives = await res.json();
                                                                            // console.log(creatives);

                                                                            resolve({
                                                                                data: creatives[0],
                                                                                page: 0,
                                                                                totalCount: creatives[0].length
                                                                            });

                                                                            setLoading(false);
                                                                        })
                                                                    }
                                                                    client={props.client}
                                                                    themeColor={props.themeColor}
                                                                    options={{
                                                                        headerStyle: headerStyleObj,
                                                                        grouping: false,
                                                                        searchFieldVariant: "outlined",
                                                                        exportButton: true,
                                                                        exportAllData: true,
                                                                        search: false,
                                                                        paging: (creativeData.length > 10),
                                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                                        pageSizeOptions: [10, 25, 50, 100],
                                                                        showTitle: true,
                                                                        toolbar: false,
                                                                        sort: true
                                                                    }}
                                                                    columns={[
                                                                        { title: "", field: "CREATIVE", cellStyle: { width2: 'auto', backgroundColor: lightenHexColor("#ececec", 0), }, render: rowdata => <Typography style={{ minWidth: (admintoken.token >= 8 ? 500 : 815), textAlign: 'left' }}></Typography> },

                                                                        {
                                                                            title: "", cellStyle: { width: 'auto', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "NAME", render: rowdata => (
                                                                                <div>
                                                                                    {!imagesLoading ? (
                                                                                        <ShowImage thename={rowdata.NAME} />
                                                                                    ) : (
                                                                                        <CircularProgress />
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        }
                                                                        , {
                                                                            title: 'Impressions',
                                                                            cellStyle: {
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Clicks',
                                                                            field: 'CLICKS',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'CTR',
                                                                            field: 'CTR',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Conversions',
                                                                            field: 'CONVERSIONS',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Conv %',
                                                                            field: 'CONV_RATE',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            title: 'Revenue',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                                        },
                                                                        {
                                                                            title: 'Avg. Gift',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                                        },
                                                                        {
                                                                            title: 'CPC',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'CPM',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'Cost',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'ROAS',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 6 || props.client.showCost ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                                        }]}
                                                                />
                                                            ) : (
                                                                <div>
                                                                    <Typography color='secondary' variant='h6' align='center'>Loading Image Data</Typography>
                                                                    <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    tooltip: 'Show Headlines',
                                                    icon: () => <MdOutlineShortText color={props.themeColor.secondary} size={20} />,
                                                    openIcon: () => <FaWindowClose color={props.themeColor.secondary} size={20} />,
                                                    render: rowData => (
                                                        <div style={{ marginLeft: combinedCellWidth }}>
                                                            {creativeData ? (
                                                                <MaterialTable
                                                                    title="Headlines"
                                                                    data={query =>
                                                                        new Promise(async (resolve, reject) => {
                                                                            var myHeaders = new Headers();
                                                                            myHeaders.append("Content-Type", "application/json");
                                                                            var raw = JSON.stringify({
                                                                                "agroup": rowData.ADGROUP,
                                                                                "sdate": props.sDate,
                                                                                "edate": props.eDate
                                                                            });
                                                                            var requestOptions = {
                                                                                method: 'POST',
                                                                                headers: myHeaders,
                                                                                body: raw,
                                                                                redirect: 'follow'
                                                                            };
                                                                            const res = await fetch(appVars.api_url + "/meta/creatives/adgroup", requestOptions);
                                                                            const creatives = await res.json();
                                                                            resolve({
                                                                                data: creatives[1],
                                                                                page: 0,
                                                                                totalCount: creatives[1].length
                                                                            });

                                                                            const imageUrls = await Promise.all(
                                                                                creatives[0].map(async (item) => {
                                                                                    const imageUrl = await fetchImageUrl(item.NAME);
                                                                                    return { [item.NAME]: imageUrl };
                                                                                })
                                                                            );
                                                                            setImageUrls(prevUrls => ({ ...prevUrls, ...Object.assign({}, ...imageUrls) }));

                                                                            setLoading(false);
                                                                        })
                                                                    }
                                                                    client={props.client}
                                                                    themeColor={props.themeColor}
                                                                    options={{
                                                                        headerStyle: headerStyleObj,
                                                                        grouping: false,
                                                                        searchFieldVariant: "outlined",
                                                                        exportButton: true,
                                                                        exportAllData: true,
                                                                        search: false,
                                                                        paging: (creativeData.length > 10),
                                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                                        pageSizeOptions: [10, 25, 50, 100],
                                                                        showTitle: false,
                                                                        toolbar: false,
                                                                        sort: true
                                                                    }}
                                                                    columns={[
                                                                        { title: "", field: "CREATIVE", cellStyle: { width2: 'auto', backgroundColor: lightenHexColor("#ececec", 0) }, render: rowdata => <Typography style={{ minWidth: (admintoken.token >= 8 ? 500 : 815), textAlign: 'left' }}></Typography> },
                                                                        {
                                                                            title: "Title", cellStyle: { width: 'auto' }, field: "TITLE", render: rowdata => (
                                                                                <div>
                                                                                    <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.TITLE}</Typography>
                                                                                </div>
                                                                            )
                                                                        },
                                                                        {
                                                                            title: 'Impressions',
                                                                            cellStyle: {
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Clicks',
                                                                            field: 'CLICKS',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'CTR',
                                                                            field: 'CTR',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Conversions',
                                                                            field: 'CONVERSIONS',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Conv %',
                                                                            field: 'CONV_RATE',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            title: 'Revenue',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                                        },
                                                                        {
                                                                            title: 'Avg. Gift',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                                        },
                                                                        {
                                                                            title: 'CPC',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'CPM',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'Cost',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'ROAS',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 6 || props.client.showCost ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                                        }
                                                                    ]}
                                                                />
                                                            ) : (
                                                                <div>
                                                                    <Typography color='secondary' variant='h6' align='center'>Loading Title / Headline Data</Typography>
                                                                    <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    icon: () => <MdOutlineTextSnippet color={props.themeColor.secondary} size={20} />,
                                                    openIcon: () => <FaWindowClose color={props.themeColor.secondary} size={20} />,
                                                    tooltip: 'Show Copy',
                                                    render: rowData => (
                                                        <div style={{ marginLeft: combinedCellWidth }}>
                                                            {creativeData ? (
                                                                <MaterialTable
                                                                    title="AD Copy"
                                                                    data={query =>
                                                                        new Promise(async (resolve, reject) => {
                                                                            var myHeaders = new Headers();
                                                                            myHeaders.append("Content-Type", "application/json");
                                                                            var raw = JSON.stringify({
                                                                                "agroup": rowData.ADGROUP,
                                                                                "sdate": props.sDate,
                                                                                "edate": props.eDate
                                                                            });
                                                                            var requestOptions = {
                                                                                method: 'POST',
                                                                                headers: myHeaders,
                                                                                body: raw,
                                                                                redirect: 'follow'
                                                                            };
                                                                            const res = await fetch(appVars.api_url + "/meta/creatives/adgroup", requestOptions);
                                                                            const creatives = await res.json();
                                                                            resolve({
                                                                                data: creatives[2],
                                                                                page: 0,
                                                                                totalCount: creatives[2].length
                                                                            });

                                                                            const imageUrls = await Promise.all(
                                                                                creatives[0].map(async (item) => {
                                                                                    const imageUrl = await fetchImageUrl(item.NAME);
                                                                                    return { [item.NAME]: imageUrl };
                                                                                })
                                                                            );
                                                                            setImageUrls(prevUrls => ({ ...prevUrls, ...Object.assign({}, ...imageUrls) }));

                                                                            setLoading(false);
                                                                        })
                                                                    }
                                                                    client={props.client}
                                                                    themeColor={props.themeColor}
                                                                    options={{
                                                                        headerStyle: headerStyleObj,
                                                                        grouping: false,
                                                                        searchFieldVariant: "outlined",
                                                                        exportButton: true,
                                                                        exportAllData: true,
                                                                        search: false,
                                                                        paging: (creativeData.length > 10),
                                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                                        pageSizeOptions: [10, 25, 50, 100],
                                                                        showTitle: false,
                                                                        toolbar: false,
                                                                        sort: true
                                                                    }}
                                                                    columns={[
                                                                        { title: "", field: "CREATIVE", cellStyle: { width2: 'auto', backgroundColor: lightenHexColor("#ececec", 0) }, render: rowdata => <Typography style={{ minWidth: (admintoken.token >= 8 ? 500 : 815), textAlign: 'left' }}></Typography> },
                                                                        {
                                                                            title: "Copy", cellStyle: { width: 'auto' }, field: "BODY_COPY", render: rowdata => (
                                                                                <div>
                                                                                    <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.BODY_COPY}</Typography>
                                                                                </div>
                                                                            )
                                                                        },
                                                                        {
                                                                            title: 'Impressions',
                                                                            cellStyle: {
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Clicks',
                                                                            field: 'CLICKS',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'CTR',
                                                                            field: 'CTR',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Conversions',
                                                                            field: 'CONVERSIONS',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Conv %',
                                                                            field: 'CONV_RATE',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            title: 'Revenue',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                                        },
                                                                        {
                                                                            title: 'Avg. Gift',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                                        },
                                                                        {
                                                                            title: 'CPC',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'CPM',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'Cost',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'ROAS',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 6 || props.client.showCost ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                                        }
                                                                    ]}
                                                                />
                                                            ) : (
                                                                <div>
                                                                    <Typography color='secondary' variant='h6' align='center'>Loading Copy Data</Typography>
                                                                    <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    icon: () => <MdOutlineCallToAction color={props.themeColor.secondary} size={20} />,
                                                    openIcon: () => <FaWindowClose color={props.themeColor.secondary} size={20} />,
                                                    tooltip: 'Show CTA',
                                                    render: rowData => (
                                                        <div style={{ marginLeft: combinedCellWidth }}>
                                                            {creativeData ? (
                                                                <MaterialTable
                                                                    title="Call to action"
                                                                    data={query =>
                                                                        new Promise(async (resolve, reject) => {
                                                                            var myHeaders = new Headers();
                                                                            myHeaders.append("Content-Type", "application/json");
                                                                            var raw = JSON.stringify({
                                                                                "agroup": rowData.ADGROUP,
                                                                                "sdate": props.sDate,
                                                                                "edate": props.eDate
                                                                            });
                                                                            var requestOptions = {
                                                                                method: 'POST',
                                                                                headers: myHeaders,
                                                                                body: raw,
                                                                                redirect: 'follow'
                                                                            };
                                                                            const res = await fetch(appVars.api_url + "/meta/creatives/adgroup", requestOptions);
                                                                            const creatives = await res.json();
                                                                            resolve({
                                                                                data: creatives[3],
                                                                                page: 0,
                                                                                totalCount: creatives[3].length
                                                                            });

                                                                            const imageUrls = await Promise.all(
                                                                                creatives[0].map(async (item) => {
                                                                                    const imageUrl = await fetchImageUrl(item.NAME);
                                                                                    return { [item.NAME]: imageUrl };
                                                                                })
                                                                            );
                                                                            setImageUrls(prevUrls => ({ ...prevUrls, ...Object.assign({}, ...imageUrls) }));

                                                                            setLoading(false);
                                                                        })
                                                                    }
                                                                    client={props.client}
                                                                    themeColor={props.themeColor}
                                                                    options={{
                                                                        grouping: false,
                                                                        searchFieldVariant: "outlined",
                                                                        exportButton: true,
                                                                        exportAllData: true,
                                                                        search: false,
                                                                        paging: (creativeData.length > 10),
                                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                                        pageSizeOptions: [10, 25, 50, 100],
                                                                        showTitle: false,
                                                                        toolbar: false,
                                                                        sort: true
                                                                    }}
                                                                    columns={[
                                                                        { title: "", field: "CREATIVE", cellStyle: { width2: 'auto', backgroundColor: lightenHexColor("#ececec", 0) }, render: rowdata => <Typography style={{ minWidth: (admintoken.token >= 8 ? 500 : 815), textAlign: 'left' }}></Typography> },
                                                                        {
                                                                            title: "CTA", cellStyle: { width: 'auto' }, field: "CTA", render: rowdata => (
                                                                                <div>
                                                                                    <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CTA}</Typography>
                                                                                </div>
                                                                            )
                                                                        },
                                                                        {
                                                                            title: 'Impressions',
                                                                            cellStyle: {
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Clicks',
                                                                            field: 'CLICKS',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'CTR',
                                                                            field: 'CTR',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Conversions',
                                                                            field: 'CONVERSIONS',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            cellStyle: {
                                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            },
                                                                            title: 'Conv %',
                                                                            field: 'CONV_RATE',
                                                                            render: (rowdata) => (
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                                                    </Box>
                                                                                </div>
                                                                            ),
                                                                        },
                                                                        {
                                                                            title: 'Revenue',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                                        },
                                                                        {
                                                                            title: 'Avg. Gift',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                                        },
                                                                        {
                                                                            title: 'CPC',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'CPM',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'Cost',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 6 || props.client.showCost ? false : true
                                                                        },
                                                                        {
                                                                            title: 'ROAS',
                                                                            cellStyle: {

                                                                                textAlign: 'center',
                                                                                padding: theme.spacing(0),
                                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                                            }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 6 || props.client.showCost ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                                        }
                                                                    ]}
                                                                />
                                                            ) : (
                                                                <div>
                                                                    <Typography color='secondary' variant='h6' align='center'>Loading Copy Data</Typography>
                                                                    <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ),
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <div>
                                            <Typography color='secondary' variant='h6' align='center'>Loading Creative Data</Typography>
                                            <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                            <ButtonGroup size="medium">
                                <Button variant="text" title='Print Report'
                                    onClick={() => generatePDF(reportRef3, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                ><FcPrint size={20} />
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <div ref={reportRef3}>
                            <Grid container spacing={2} alignContent="space-between">
                                <Grid item sm={6} xs={12}>
                                    {creativeData ? (
                                        <TableCard
                                            title="Images"
                                            data={creativeData[1][0]}
                                            count={Math.min(creativeData[1][0].length, 10)}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            options={{
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: false,
                                                paging: (creativeData.length > 10),
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                {
                                                    title: "", field: "NAME", render: rowdata => (
                                                        <div>
                                                            {!imagesLoading ? (
                                                                <ShowImage thename={rowdata.NAME} imageUrl={imageUrls[rowdata.NAME]} />
                                                            ) : (
                                                                <CircularProgress />
                                                            )}
                                                        </div>
                                                    )
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <div>
                                            <Typography color='secondary' variant='h6' align='center'>Loading Image Data</Typography>
                                            <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    {creativeData ? (
                                        <>
                                            <TableCard
                                                title="Title"
                                                data={creativeData[1][1]}
                                                count={Math.min(creativeData[1][1].length, 10)}
                                                client={props.client}
                                                themeColor={props.themeColor}
                                                options={{
                                                    grouping: false,
                                                    searchFieldVariant: "outlined",
                                                    exportButton: true,
                                                    exportAllData: true,
                                                    search: false,
                                                    paging: (creativeData.length > 10),
                                                    exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                    pageSizeOptions: [10, 25, 50, 100],
                                                    showTitle: false,
                                                    toolbar: true,
                                                    sort: true
                                                }}
                                                columns={[
                                                    {
                                                        title: "Title", field: "TITLE", render: rowdata => (
                                                            <div>
                                                                <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.TITLE}</Typography>
                                                            </div>
                                                        )
                                                    },
                                                ]}
                                            />
                                            <TableCard
                                                title="Call to action"
                                                data={creativeData[1][3]}
                                                count={Math.min(creativeData[1][3].length, 10)}
                                                client={props.client}
                                                themeColor={props.themeColor}
                                                options={{
                                                    grouping: false,
                                                    searchFieldVariant: "outlined",
                                                    exportButton: true,
                                                    exportAllData: true,
                                                    search: false,
                                                    paging: (creativeData.length > 10),
                                                    exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                    pageSizeOptions: [10, 25, 50, 100],
                                                    showTitle: false,
                                                    toolbar: true,
                                                    sort: true
                                                }}
                                                columns={[
                                                    {
                                                        title: "CTA", field: "CTA", render: rowdata => (
                                                            <div>
                                                                <Typography style={{ textAlign: 'left', minWidth: 300, fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CTA}</Typography>
                                                            </div>
                                                        )
                                                    },
                                                ]}
                                            />
                                        </>
                                    ) : (
                                        <div>
                                            <Typography color='secondary' variant='h6' align='center'>Loading Title / Headline Data</Typography>
                                            <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item sm={12} xs={12} />
                            </Grid>
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={3} data-title="Demographics">

                        <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                            <ButtonGroup size="medium">
                                <Button variant="text" title='Print Report'
                                    onClick={() => generatePDF(reportRef3, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                ><FcPrint size={20} />
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <div ref={reportRef3}>


                            {agePie && (
                                <Grid container spacing={3}>
                                    <Grid item sm={6}>
                                        <PieChartCard title="Clicks - By Age" data={agePie} client={props.client} themeColor={props.themeColor} />
                                    </Grid>

                                    <Grid item sm={6}>
                                        <PieChartCard title="Clicks - By Gender" data={genderData} client={props.client} themeColor={props.themeColor} gender={true} />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <StackedBarChartAgeComponent themeColor={props.themeColor} data={ageData} layout="vertical" />
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={4} data-title="Devices">
                    <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                            <ButtonGroup size="medium">
                                <Button variant="text" title='Print Report'
                                    onClick={() => generatePDF(reportRef4, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                ><FcPrint size={20} />
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <div ref={reportRef4}>
                            {devicesData ? (
                                <TableCard
                                    options={{
                                        grouping: false,
                                        searchFieldVariant: "outlined",
                                        headerStyle: headerStyleObj,
                                        exportButton: true,
                                        exportAllData: true,
                                        search: true,
                                        paging: (devicesData.length > 10),
                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                        pageSizeOptions: [10, 25, 50, 100],
                                        showTitle: false,
                                        toolbar: true,
                                        sort: true
                                    }}
                                    title=""
                                    data={devicesData}
                                    count={Math.min(devicesData.length, 10)}
                                    client={props.client}
                                    themeColor={props.themeColor}
                                    columns={[
                                        {
                                            title: "Device",
                                            cellStyle: {
                                                backgroundColor: lightenHexColor("#cecece", 0),
                                                textAlign: 'center',
                                                width: 300,
                                                padding: theme.spacing(1),
                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                            },

                                            field: "DEVICE", render: rowdata => <div>{devices[rowdata.DEVICE]}<Typography fontSize={fontSize} style={{ padding: 0, margin: 0 }}>{rowdata.DEVICE}</Typography></div>
                                        },
                                    ]}
                                />
                            ) : (
                                <div>
                                    <Typography color='secondary' variant='h6' align='center'>Loading Device Data</Typography>
                                    <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress>
                                </div>
                            )}
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={5} data-title="Comments">
                        <div>
                            <Comments eDate={props.eDate} sDate={props.sDate} themeColor={props.themeColor} admintoken={admintoken} view={(admintoken.token >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")} type="meta"></Comments>
                        </div>
                    </TabPanel>
                </div>
            ) : (
                <div style={{ paddingTop: theme.spacing(8) }}>
                    <Typography color='secondary' variant='h6' style={{ color: props.themeColor }} align='center'>Loading {props.title} Data</Typography>
                    <LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress>
                </div>
            )}
        </ThemeProvider>
        </div>
    );
};

Meta.propTypes = {};

export default Meta;
