import {
    AppBar, Button, ButtonGroup, Card, CardMedia, Grid, Typography, LinearProgress, Tabs, Tab, Box, makeStyles, Dialog,
    IconButton,
    CardHeader,
} from '@material-ui/core';
import { React, useState, useEffect, useRef } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ChartCard from '../../../stackadapt-chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { lightenHexColor, generatePDF, calculatePercentageChange } from '../../../../dwi-functions';
import moment from 'moment';
import Comments from '../../../../system/control/comments';
import appVars from '../../../../appVars.json';
import { FaWindowClose, FaImages, FaVideo } from "react-icons/fa";
import MaterialTable from 'material-table';
import { FcPrint } from "react-icons/fc";
import CloseIcon from '@material-ui/icons/Close';
const fontSize = 'initial';

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        marginTop: theme.spacing(4),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

    },
    card: {
        maxWidth: 800, // Limit the video size
        width: '100%',
        boxShadow: theme.shadows[5],
    },
    video: {
        width: '100%',
        maxWidth: 200,
        borderRadius: theme.shape.borderRadius,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        color: "white",
    },
}));










function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}













const StackAdapt = (props) => {
    const [campaignData, setCampaignData] = useState();
    const [data, setStackAdaptData] = useState();
    const [typeSelect] = useState([{ CAMPAIGN_TYPE: 'native' }]);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);
    const [admintoken] = useState(props.admintoken);
    const [combinedCellWidth, setCombinedCellWidth] = useState(0);
    const pDate = new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.sDate)) + "-" + new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.eDate));
    const classes = useStyles();
    const actionCellRef = useRef(null);
    const firstCellRef = useRef(null);


    const [open, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const handleOpen = (url) => {
        setVideoUrl(url); // Set the clicked video URL
        setOpen(true); // Open the dialog
    };

    const handleClose = () => {
        setOpen(false);
        setVideoUrl(''); // Clear the URL when closing
    };

    const reportRef1 = useRef()
    const reportRef2 = useRef()
    const reportRef3 = useRef()
    const reportRef4 = useRef()

    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary, // Default primary color
            },
            secondary: {
                main: props.themeColor.secondary, // Default primary color
            },
        },
        overrides: {
            // Style overrides for MuiTab
            MuiTab: {

                // Target the background color of the selected tab
                root: {
                    '&$selected': {
                        color: props.themeColor.secondary, // Example: Deep orange color for the selected tab
                        backgroundColor: props.themeColor.primary // Example: Deep orange color for the selected tab
                    }
                }

            },
            MuiInputAdornment: {
                root: {
                    color: props.themeColor.secondary,
                },
            },
            MuiIcon: {
                root: {
                    color: lightenHexColor(props.themeColor.secondary, 25),
                },
            },
            MuiOutlinedInput: {
                root: {
                    padding: 0,
                    background: lightenHexColor(props.themeColor.secondary, 45),
                },
                input: {
                    padding: 7,
                },
            },
            MuiPaper: {
                elevation2: {
                    boxShadow: 'none',
                },
                root: {
                    border: '0px solid ' + lightenHexColor(props.themeColor.secondary, 25),
                },
            },
        },


    });


    const headerStyleObj = {
        textAlign: 'center',
        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
        color: lightenHexColor(props.themeColor.primary, 0),
        fontSize: fontSize,
        border: '1px solid ' + props.themeColor.secondary,
        paddingTop: 5,
        paddingBottom: 5,
        maxWidth: 75,
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    var detailsOptions = {
        headerStyle: headerStyleObj,
        grouping: false,
        searchFieldVariant: "outlined",
        exportButton: true,
        exportAllData: true,
        search: false,
        // paging: (creativeData.length > 10),
        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
        //  pageSizeOptions: [10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
        sort: true
    };


    async function fetchReportData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate,
            "token": props.client.saKey
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res4 = await fetch(appVars.api_url + "/stackadapt/campaigns/id", requestOptions);
        //  console.log(typeSelect);
        res4
            .json()
            .then((res4) => {
                //console.log(res4.channel);
                setStackAdaptData(res4);
                setCampaignData(res4.channel);
                // setAccountData(res4[0]['accounts']);
                // console.log(res4);
                // setTypes(res4);
                // setCustomers(res4[0]['accountnames']);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });



    }

    useEffect(() => {

        if (actionCellRef.current && firstCellRef.current) {
            setCombinedCellWidth(actionCellRef.current.offsetWidth + firstCellRef.current.offsetWidth);
        }
        // if(!data){
        fetchReportData();
        //  console.log(data);
        //  }
    }, [props, typeSelect]);





    return (
        <div >

            <ThemeProvider theme={theme}>
                {(data ?
                    <div>

                        <AppBar variant="outlined" color='secondary' position="relative" style={{ margin: theme.spacing(0) }} >
                            <Grid container alignItems='center' >
                                <Grid item sm={12}>

                                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto" >
                                        <Tab label="Native ADS" {...a11yProps(0)} />
                                        <Tab label="Display ADS" {...a11yProps(1)} />
                                        <Tab label="Video ADS" {...a11yProps(2)} />
                                        <Tab label="Audio ADS" {...a11yProps(3)} />
                                        <Tab label="Notes" {...a11yProps(4)} />
                                    </Tabs>

                                </Grid>
                            </Grid>
                        </AppBar>


                        <TabPanel value={value} index={0} id="native">

                            <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                                <ButtonGroup size="medium">
                                    <Button variant="text" title='Print Report'
                                        onClick={() => generatePDF(reportRef1, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                    ><FcPrint size={20} />
                                    </Button>
                                </ButtonGroup>
                            </Box>






                            {(data.channel.native ?
                                <div ref={reportRef1} style={{ padding: theme.spacing(1) }}>
                                    <Grid container spacing={2} alignContent="space-between">
                                        {admintoken.token >= 8 && (
                                            <Grid container spacing={1} justifyContent="flex-end" style={{ paddingRight: theme.spacing(1) }}>
                                                <Grid item sm={1}>
                                                    <Box
                                                        p={1}
                                                        bgcolor={lightenHexColor(props.themeColor.secondary, 40)}
                                                        mt={1}
                                                        border={1}
                                                        borderColor={lightenHexColor(props.themeColor.secondary, 10)}
                                                    >
                                                        <Typography>
                                                            Cost:{" "}
                                                            <NumericFormat
                                                                decimalScale={2}
                                                                value={data.channel.native.stats.cost?.count || 0}
                                                                thousandSeparator=","
                                                                displayType="text"
                                                                prefix="$"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={1} style={{ display: 'initial' }}>
                                                    <Box
                                                        p={1}
                                                        bgcolor={lightenHexColor(props.themeColor.secondary, 40)}
                                                        mt={1}
                                                        border={1}
                                                        borderColor={lightenHexColor(props.themeColor.secondary, 10)}
                                                    >
                                                        <Typography>
                                                            ROAS:{" "}
                                                            <NumericFormat
                                                                decimalScale={2}
                                                                value={
                                                                    (data.channel.native.stats.revenue?.count || 0) /
                                                                    (data.channel.native.stats.cost?.count || 1) // Avoid division by 0
                                                                }
                                                                displayType="text"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {/* Impressions */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Impressions"
                                                explain={appVars.tooltips.impression}
                                                data={data.channel.native.stats.impressions}
                                                previousdata={data.previousChannel.native?.stats.impressions || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.native.stats.impressions.count,
                                                    data.previousChannel.native?.stats.impressions?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Clicks */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Clicks"
                                                explain={appVars.tooltips.click}
                                                data={data.channel.native.stats.clicks}
                                                previousdata={data.previousChannel.native?.stats.clicks || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.native.stats.clicks.count,
                                                    data.previousChannel.native?.stats.clicks?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* CTR */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="CTR"
                                                explain={appVars.tooltips.ctr}
                                                data={data.channel.native.stats.ctr}
                                                previousdata={data.previousChannel.native?.stats.ctr || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.native.stats.ctr.count,
                                                    data.previousChannel.native?.stats.ctr?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* CPM */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="CPM"
                                                explain={appVars.tooltips.cpm}
                                                data={data.channel.native.stats.cpm}
                                                previousdata={data.previousChannel.native?.stats.cpm || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.native.stats.cpm.count,
                                                    data.previousChannel.native?.stats.cpm?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} alignContent="space-between">
                                        {/* Conversions */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Conversions"
                                                explain={appVars.tooltips.conversion}
                                                data={data.channel.native.stats.conversions}
                                                previousdata={data.previousChannel.native?.stats.conversions || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.native.stats.conversions.count,
                                                    data.previousChannel.native?.stats.conversions?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Revenue */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Revenue"
                                                explain={appVars.tooltips.revenue}
                                                data={data.channel.native.stats.revenue}
                                                previousdata={data.previousChannel.native?.stats.revenue || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.native.stats.revenue.count,
                                                    data.previousChannel.native?.stats.revenue?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Conversion Rate */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Conversion Rate"
                                                explain={appVars.tooltips.conv_rate}
                                                data={data.channel.native.stats.convrate}
                                                previousdata={data.previousChannel.native?.stats.convrate || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.native.stats.convrate.count,
                                                    data.previousChannel.native?.stats.convrate?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Avg Gift */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Avg Gift"
                                                explain={appVars.tooltips.avg_gift}
                                                data={data.channel.native.stats.avgGift}
                                                previousdata={data.previousChannel.native?.stats.avgGift || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.native.stats.avgGift.count,
                                                    data.previousChannel.native?.stats.avgGift?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>
                                    </Grid>



                                    <Grid container spacing={2} alignContent="space-between">

                                        <Grid item sm={12} xs={12} >
                                            {(campaignData ?
                                                <MaterialTable
                                                    options={{

                                                        cellStyle: {
                                                            border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                            padding: theme.spacing(1)
                                                        },
                                                        headerStyle: headerStyleObj,
                                                        grouping: false,
                                                        searchFieldVariant: "outlined",
                                                        exportButton: true,
                                                        exportAllData: true,
                                                        search: true,
                                                        paging: (campaignData.native.campaigns.length > 10 ? true : false),
                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                        pageSizeOptions: [10, 25, 50, 100],
                                                        showTitle: false,
                                                        toolbar: true,
                                                        sort: true
                                                    }}
                                                    title="Campaigns"
                                                    data={campaignData.native.campaigns}
                                                    count={(campaignData.native.campaigns.length > 10 ? 10 : campaignData.native.campaigns.length)}
                                                    client={props.client}
                                                    themeColor={props.themeColor}
                                                    columns={[
                                                        { title: "Campaign", cellStyle: { width: '100%', textAlign: 'left', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "name", render: rowdata => <Typography>{rowdata.name}</Typography> },
                                                        {
                                                            title: 'Impressions',
                                                            cellStyle: {
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Clicks',
                                                            field: 'CLICKS',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'CTR',
                                                            field: 'CTR',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Conversions',
                                                            field: 'CONVERSIONS',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Conv %',
                                                            field: 'CONV_RATE',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            title: 'Revenue',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                        },
                                                        {
                                                            title: 'Avg. Gift',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                        },
                                                        {
                                                            title: 'CPC',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'CPM',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'Cost',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'ROAS',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 8 ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                        }
                                                    ]}
                                                    detailPanel={[
                                                        {
                                                            tooltip: 'Show Creative',
                                                            icon: () => <FaImages color={props.themeColor.secondary} size={20} />,
                                                            openIcon: () => <FaWindowClose color={props.themeColor.secondary} size={20} />,
                                                            render: rowData => (
                                                                <div style={{ marginLeft: combinedCellWidth }}>

                                                                    <TableCard
                                                                        title="Images"
                                                                        data={query =>

                                                                            new Promise(async (resolve, reject) => {

                                                                                var myHeaders = new Headers();
                                                                                myHeaders.append("Content-Type", "application/json");
                                                                                var raw = JSON.stringify({
                                                                                    "campaign": rowData.id,
                                                                                    "cid": props.client.clientid,
                                                                                    "sdate": props.sDate,
                                                                                    "edate": props.eDate
                                                                                });

                                                                                var requestOptions = {
                                                                                    method: 'POST',
                                                                                    headers: myHeaders,
                                                                                    body: raw,
                                                                                    redirect: 'follow'
                                                                                };
                                                                                const res = await fetch(appVars.api_url + "/stackadapt/creatives/", requestOptions);
                                                                                const creatives = await res.json();
                                                                                // console.log(creatives);

                                                                                resolve({
                                                                                    data: creatives,
                                                                                    page: 0,
                                                                                    totalCount: creatives.length
                                                                                });

                                                                                setLoading(false);
                                                                            })

                                                                        }
                                                                        client={props.client}
                                                                        themeColor={props.themeColor}
                                                                        options={detailsOptions}
                                                                        columns={[
                                                                            { title: "", field: "size", cellStyle: { width2: 'auto', backgroundColor: lightenHexColor("#ececec", 0), }, render: rowdata => <Typography style={{ minWidth: (admintoken.token >= 8 ? 500 : 815), textAlign: 'left' }}></Typography> },

                                                                            {
                                                                                title: "", cellStyle: { width: 'auto', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "name", render: rowdata => (
                                                                                    <div>
                                                                                        <img alt='' style={{ maxWidth: 150 }} src={rowdata.name} onError={(e) => {
                                                                                            e.src = 'https://api.digitalwerks.com:7325/imageFiles/no-image-icon.png';
                                                                                        }} />
                                                                                        <Typography variant='body2' color="textSecondary" align='center'>{rowdata.size}</Typography>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        ]}
                                                                    />

                                                                </div>
                                                            ),
                                                        },
                                                    ]}
                                                />
                                                : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                        </Grid>






                                    </Grid>
                                </div>
                                : <Typography style={{ margin: theme.spacing(2) }} align="center" gutterBottom>No Native Data</Typography>)}
                        </TabPanel>

                        <TabPanel value={value} index={1} id="display">
                            <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                                <ButtonGroup size="medium">
                                    <Button variant="text" title='Print Report'
                                        onClick={() => generatePDF(reportRef2, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                    ><FcPrint size={20} />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                            {(data.channel.display ?
                                <div ref={reportRef2} style={{ padding: theme.spacing(1) }}>
                                    <Grid container spacing={2} alignContent="space-between">
                                        {admintoken.token >= 8 && (
                                            <Grid container spacing={1} justifyContent="flex-end" style={{ paddingRight: theme.spacing(1) }}>
                                                <Grid item sm={1}>
                                                    <Box
                                                        p={1}
                                                        bgcolor={lightenHexColor(props.themeColor.secondary, 40)}
                                                        mt={1}
                                                        border={1}
                                                        borderColor={lightenHexColor(props.themeColor.secondary, 10)}
                                                    >
                                                        <Typography>
                                                            Cost:{" "}
                                                            <NumericFormat
                                                                decimalScale={2}
                                                                value={data.channel.display.stats.cost?.count || 0}
                                                                thousandSeparator=","
                                                                displayType="text"
                                                                prefix="$"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={1} style={{ display: 'initial' }}>
                                                    <Box
                                                        p={1}
                                                        bgcolor={lightenHexColor(props.themeColor.secondary, 40)}
                                                        mt={1}
                                                        border={1}
                                                        borderColor={lightenHexColor(props.themeColor.secondary, 10)}
                                                    >
                                                        <Typography>
                                                            ROAS:{" "}
                                                            <NumericFormat
                                                                decimalScale={2}
                                                                value={
                                                                    (data.channel.display.stats.revenue?.count || 0) /
                                                                    (data.channel.display.stats.cost?.count || 1) // Avoid division by zero
                                                                }
                                                                displayType="text"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {/* Impressions */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Impressions"
                                                explain={appVars.tooltips.impression}
                                                data={data.channel.display.stats.impressions}
                                                previousdata={data.previousChannel.display?.stats.impressions || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.display.stats.impressions.count,
                                                    data.previousChannel.display?.stats.impressions?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Clicks */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Clicks"
                                                explain={appVars.tooltips.click}
                                                data={data.channel.display.stats.clicks}
                                                previousdata={data.previousChannel.display?.stats.clicks || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.display.stats.clicks.count,
                                                    data.previousChannel.display?.stats.clicks?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* CTR */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="CTR"
                                                explain={appVars.tooltips.ctr}
                                                data={data.channel.display.stats.ctr}
                                                previousdata={data.previousChannel.display?.stats.ctr || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.display.stats.ctr.count,
                                                    data.previousChannel.display?.stats.ctr?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* CPM */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="CPM"
                                                explain={appVars.tooltips.cpm}
                                                data={data.channel.display.stats.cpm}
                                                previousdata={data.previousChannel.display?.stats.cpm || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.display.stats.cpm.count,
                                                    data.previousChannel.display?.stats.cpm?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} alignContent="space-between">
                                        {/* Conversions */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Conversions"
                                                explain={appVars.tooltips.conversion}
                                                data={data.channel.display.stats.conversions}
                                                previousdata={data.previousChannel.display?.stats.conversions || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.display.stats.conversions.count,
                                                    data.previousChannel.display?.stats.conversions?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Revenue */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Revenue"
                                                explain={appVars.tooltips.revenue}
                                                data={data.channel.display.stats.revenue}
                                                previousdata={data.previousChannel.display?.stats.revenue || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.display.stats.revenue.count,
                                                    data.previousChannel.display?.stats.revenue?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Conversion Rate */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Conversion Rate"
                                                explain={appVars.tooltips.conv_rate}
                                                data={data.channel.display.stats.convrate}
                                                previousdata={data.previousChannel.display?.stats.convrate || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.display.stats.convrate.count,
                                                    data.previousChannel.display?.stats.convrate?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Avg Gift */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Avg Gift"
                                                explain={appVars.tooltips.avg_gift}
                                                data={data.channel.display.stats.avgGift}
                                                previousdata={data.previousChannel.display?.stats.avgGift || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.display.stats.avgGift.count,
                                                    data.previousChannel.display?.stats.avgGift?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>
                                    </Grid>



                                    <Grid container spacing={2} alignContent="space-between">

                                        <Grid item sm={12} xs={12} >
                                            {(campaignData ?
                                                <MaterialTable
                                                    options={{

                                                        cellStyle: {
                                                            border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                            padding: theme.spacing(1)
                                                        },
                                                        headerStyle: headerStyleObj,
                                                        grouping: false,
                                                        searchFieldVariant: "outlined",
                                                        exportButton: true,
                                                        exportAllData: true,
                                                        search: true,
                                                        paging: (campaignData.display.campaigns.length > 10 ? true : false),
                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                        pageSizeOptions: [10, 25, 50, 100],
                                                        showTitle: false,
                                                        toolbar: true,
                                                        sort: true
                                                    }}
                                                    title="Campaigns"
                                                    data={campaignData.display.campaigns}
                                                    count={(campaignData.display.campaigns.length > 10 ? 10 : campaignData.display.campaigns.length)}
                                                    client={props.client}
                                                    themeColor={props.themeColor}
                                                    columns={[
                                                        { title: "Campaign", cellStyle: { width: '100%', textAlign: 'left', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "name", render: rowdata => <Typography>{rowdata.name}</Typography> },
                                                        {
                                                            title: 'Impressions',
                                                            cellStyle: {
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Clicks',
                                                            field: 'CLICKS',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'CTR',
                                                            field: 'CTR',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Conversions',
                                                            field: 'CONVERSIONS',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Conv %',
                                                            field: 'CONV_RATE',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            title: 'Revenue',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                        },
                                                        {
                                                            title: 'Avg. Gift',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                        },
                                                        {
                                                            title: 'CPC',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'CPM',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'Cost',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'ROAS',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 8 ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                        }
                                                    ]}
                                                    detailPanel={[
                                                        {
                                                            tooltip: 'Show Creative',
                                                            icon: () => <FaImages color={props.themeColor.secondary} size={20} />,
                                                            openIcon: () => <FaWindowClose color={props.themeColor.secondary} size={20} />,
                                                            render: rowData => (
                                                                <div style={{ marginLeft: combinedCellWidth }}>

                                                                    <TableCard
                                                                        title="Images"
                                                                        data={query =>

                                                                            new Promise(async (resolve, reject) => {

                                                                                var myHeaders = new Headers();
                                                                                myHeaders.append("Content-Type", "application/json");
                                                                                var raw = JSON.stringify({
                                                                                    "campaign": rowData.id,
                                                                                    "cid": props.client.clientid,
                                                                                    "sdate": props.sDate,
                                                                                    "edate": props.eDate
                                                                                });

                                                                                var requestOptions = {
                                                                                    method: 'POST',
                                                                                    headers: myHeaders,
                                                                                    body: raw,
                                                                                    redirect: 'follow'
                                                                                };
                                                                                const res = await fetch(appVars.api_url + "/stackadapt/creatives/", requestOptions);
                                                                                const creatives = await res.json();
                                                                                // console.log(creatives);

                                                                                resolve({
                                                                                    data: creatives,
                                                                                    page: 0,
                                                                                    totalCount: creatives.length
                                                                                });

                                                                                setLoading(false);
                                                                            })

                                                                        }
                                                                        client={props.client}
                                                                        themeColor={props.themeColor}
                                                                        options={detailsOptions}
                                                                        columns={[
                                                                            { title: "", field: "size", cellStyle: { width2: 'auto', backgroundColor: lightenHexColor("#ececec", 0), }, render: rowdata => <Typography style={{ minWidth: (admintoken.token >= 8 ? 500 : 815), textAlign: 'left' }}></Typography> },

                                                                            {
                                                                                title: "", cellStyle: { width: 'auto', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "name", render: rowdata => (
                                                                                    <div>
                                                                                        <img alt='' style={{ width: 250 }} src={rowdata.name} />
                                                                                        <Typography variant='body2' color="textSecondary" align='center'>{rowdata.size}</Typography>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        ]}
                                                                    />

                                                                </div>
                                                            ),
                                                        },
                                                    ]}
                                                />
                                                : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                        </Grid>




                                    </Grid>
                                </div>
                                : <Typography style={{ margin: theme.spacing(2) }} align="center" gutterBottom>No Display Data</Typography>)}
                        </TabPanel>

                        <TabPanel value={value} index={2} id="video">
                            <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                                <ButtonGroup size="medium">
                                    <Button variant="text" title='Print Report'
                                        onClick={() => generatePDF(reportRef3, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                    ><FcPrint size={20} />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                            {(data.channel.ctv ?
                                <div ref={reportRef3} style={{ padding: theme.spacing(1) }}>
                                    <Grid container spacing={2} alignContent="space-between">
                                        {admintoken.token >= 8 && (
                                            <Grid container spacing={1} justifyContent="flex-end" style={{ paddingRight: theme.spacing(1) }}>
                                                <Grid item sm={1}>
                                                    <Box
                                                        p={1}
                                                        bgcolor={lightenHexColor(props.themeColor.secondary, 40)}
                                                        mt={1}
                                                        border={1}
                                                        borderColor={lightenHexColor(props.themeColor.secondary, 10)}
                                                    >
                                                        <Typography>
                                                            Cost:{" "}
                                                            <NumericFormat
                                                                decimalScale={2}
                                                                value={data.channel.ctv.stats.cost?.count || 0}
                                                                thousandSeparator=","
                                                                displayType="text"
                                                                prefix="$"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={1} style={{ display: 'initial' }}>
                                                    <Box
                                                        p={1}
                                                        bgcolor={lightenHexColor(props.themeColor.secondary, 40)}
                                                        mt={1}
                                                        border={1}
                                                        borderColor={lightenHexColor(props.themeColor.secondary, 10)}
                                                    >
                                                        <Typography>
                                                            ROAS:{" "}
                                                            <NumericFormat
                                                                decimalScale={2}
                                                                value={
                                                                    (data.channel.ctv.stats.revenue?.count || 0) /
                                                                    (data.channel.ctv.stats.cost?.count || 1) // Avoid division by zero
                                                                }
                                                                displayType="text"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )}

                                        {/* Impressions */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Impressions"
                                                explain={appVars.tooltips.impression}
                                                data={data.channel.ctv.stats.impressions}
                                                previousdata={data.previousChannel.ctv?.stats.impressions || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.ctv.stats.impressions.count,
                                                    data.previousChannel.ctv?.stats.impressions?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Clicks */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Clicks"
                                                explain={appVars.tooltips.click}
                                                data={data.channel.ctv.stats.clicks}
                                                previousdata={data.previousChannel.ctv?.stats.clicks || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.ctv.stats.clicks.count,
                                                    data.previousChannel.ctv?.stats.clicks?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* CTR */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="CTR"
                                                explain={appVars.tooltips.ctr}
                                                data={data.channel.ctv.stats.ctr}
                                                previousdata={data.previousChannel.ctv?.stats.ctr || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.ctv.stats.ctr.count,
                                                    data.previousChannel.ctv?.stats.ctr?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* CPM */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="CPM"
                                                explain={appVars.tooltips.cpm}
                                                data={data.channel.ctv.stats.cpm}
                                                previousdata={data.previousChannel.ctv?.stats.cpm || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.ctv.stats.cpm.count,
                                                    data.previousChannel.ctv?.stats.cpm?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} alignContent="space-between">
                                        {/* Conversions */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Conversions"
                                                explain={appVars.tooltips.conversion}
                                                data={data.channel.ctv.stats.conversions}
                                                previousdata={data.previousChannel.ctv?.stats.conversions || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.ctv.stats.conversions.count,
                                                    data.previousChannel.ctv?.stats.conversions?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Revenue */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Revenue"
                                                explain={appVars.tooltips.revenue}
                                                data={data.channel.ctv.stats.revenue}
                                                previousdata={data.previousChannel.ctv?.stats.revenue || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.ctv.stats.revenue.count,
                                                    data.previousChannel.ctv?.stats.revenue?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Conversion Rate */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Conversion Rate"
                                                explain={appVars.tooltips.conv_rate}
                                                data={data.channel.ctv.stats.convrate}
                                                previousdata={data.previousChannel.ctv?.stats.convrate || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.ctv.stats.convrate.count,
                                                    data.previousChannel.ctv?.stats.convrate?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Avg Gift */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Avg Gift"
                                                explain={appVars.tooltips.avg_gift}
                                                data={data.channel.ctv.stats.avgGift}
                                                previousdata={data.previousChannel.ctv?.stats.avgGift || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.ctv.stats.avgGift.count,
                                                    data.previousChannel.ctv?.stats.avgGift?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={2} alignContent="space-between">

                                        <Grid item sm={12} xs={12} >
                                            {(campaignData ?
                                                <MaterialTable
                                                    options={{

                                                        cellStyle: {
                                                            border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                            padding: theme.spacing(1)
                                                        },
                                                        headerStyle: headerStyleObj,
                                                        grouping: false,
                                                        searchFieldVariant: "outlined",
                                                        exportButton: true,
                                                        exportAllData: true,
                                                        search: true,
                                                        paging: (campaignData.ctv.campaigns.length > 10 ? true : false),
                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                        pageSizeOptions: [10, 25, 50, 100],
                                                        showTitle: false,
                                                        toolbar: true,
                                                        sort: true
                                                    }}
                                                    title="Campaigns"
                                                    data={campaignData.ctv.campaigns}
                                                    count={(campaignData.ctv.campaigns.length > 10 ? 10 : campaignData.ctv.campaigns.length)}
                                                    client={props.client}
                                                    themeColor={props.themeColor}
                                                    columns={[
                                                        { title: "Campaign", cellStyle: { width: '100%', textAlign: 'left', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "name", render: rowdata => <Typography>{rowdata.name}</Typography> },
                                                        {
                                                            title: 'Impressions',
                                                            cellStyle: {
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Clicks',
                                                            field: 'CLICKS',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'CTR',
                                                            field: 'CTR',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Conversions',
                                                            field: 'CONVERSIONS',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Conv %',
                                                            field: 'CONV_RATE',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            title: 'Revenue',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                        },
                                                        {
                                                            title: 'Avg. Gift',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                        },
                                                        {
                                                            title: 'CPC',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'CPM',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'Cost',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'ROAS',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 8 ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                        }
                                                    ]}
                                                    detailPanel={[
                                                        {
                                                            tooltip: 'Show Creative',
                                                            icon: () => <FaVideo color={props.themeColor.secondary} size={20} />,
                                                            openIcon: () => <FaWindowClose color={props.themeColor.secondary} size={20} />,
                                                            render: rowData => (
                                                                <div style={{ marginLeft: combinedCellWidth }}>

                                                                    <TableCard
                                                                        title="Images"
                                                                        data={query =>

                                                                            new Promise(async (resolve, reject) => {

                                                                                var myHeaders = new Headers();
                                                                                myHeaders.append("Content-Type", "application/json");
                                                                                var raw = JSON.stringify({
                                                                                    "campaign": rowData.id,
                                                                                    "cid": props.client.clientid,
                                                                                    "sdate": props.sDate,
                                                                                    "edate": props.eDate
                                                                                });

                                                                                var requestOptions = {
                                                                                    method: 'POST',
                                                                                    headers: myHeaders,
                                                                                    body: raw,
                                                                                    redirect: 'follow'
                                                                                };
                                                                                const res = await fetch(appVars.api_url + "/stackadapt/creatives/", requestOptions);
                                                                                const creatives = await res.json();
                                                                                // console.log(creatives);

                                                                                resolve({
                                                                                    data: creatives,
                                                                                    page: 0,
                                                                                    totalCount: creatives.length
                                                                                });

                                                                                setLoading(false);
                                                                            })

                                                                        }
                                                                        client={props.client}
                                                                        themeColor={props.themeColor}
                                                                        options={detailsOptions}
                                                                        columns={[
                                                                            { title: "", field: "size", cellStyle: { width2: 'auto', backgroundColor: lightenHexColor("#ececec", 0), }, render: rowdata => <Typography style={{ minWidth: (admintoken.token >= 8 ? 500 : 815), textAlign: 'left' }}></Typography> },

                                                                            {
                                                                                title: "", cellStyle: { width: 'auto', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "name", render: rowdata => (
                                                                                    <div>

                                                                                        <Card
                                                                                            className={classes.card}
                                                                                            onClick={() => handleOpen(rowdata.name)} // Pass video URL dynamically
                                                                                        >
                                                                                            <CardMedia className={classes.video}>
                                                                                                <img
                                                                                                    style={{ maxWidth: 150 }}
                                                                                                    src="https://i0.wp.com/douglasgreen.com/wp-content/uploads/2014/03/video-play-btn-featured.png?w=1920&ssl=1"
                                                                                                    alt="Video Thumbnail"

                                                                                                />
                                                                                            </CardMedia>
                                                                                        </Card>

                                                                                    </div>
                                                                                )
                                                                            }
                                                                        ]}
                                                                    />

                                                                </div>
                                                            ),
                                                        },
                                                    ]}
                                                />
                                                : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                        </Grid>






                                    </Grid>
                                </div>
                                : <Typography style={{ margin: theme.spacing(2) }} align="center" gutterBottom>No Video Data</Typography>)}
                        </TabPanel>

                        <TabPanel value={value} index={3} id="audio">
                            <Box pt={1} pb={1} mb={1} bgcolor={lightenHexColor("#000000", 90)} >
                                <ButtonGroup size="medium">
                                    <Button variant="text" title='Print Report'
                                        onClick={() => generatePDF(reportRef4, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                    ><FcPrint size={20} />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                            {(data.channel.audio ?
                                <div ref={reportRef4} style={{ padding: theme.spacing(1) }}>
                                    <Grid container spacing={2} alignContent="space-between">
                                    {(admintoken.token >= 6 || props.client.showCost === true ?
                                            <Grid container spacing={1} justifyContent="flex-end" style={{ paddingRight: theme.spacing(1) }}>
                                                <Grid item sm={1}>
                                                    <Box
                                                        p={1}
                                                        bgcolor={lightenHexColor(props.themeColor.secondary, 40)}
                                                        mt={1}
                                                        border={1}
                                                        borderColor={lightenHexColor(props.themeColor.secondary, 10)}
                                                    >
                                                        <Typography>
                                                            Cost:{" "}
                                                            <NumericFormat
                                                                decimalScale={2}
                                                                value={data.channel.audio.stats.cost?.count || 0}
                                                                thousandSeparator=","
                                                                displayType="text"
                                                                prefix="$"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={1} style={{ display: 'initial' }}>
                                                    <Box
                                                        p={1}
                                                        bgcolor={lightenHexColor(props.themeColor.secondary, 40)}
                                                        mt={1}
                                                        border={1}
                                                        borderColor={lightenHexColor(props.themeColor.secondary, 10)}
                                                    >
                                                        <Typography>
                                                            ROAS:{" "}
                                                            <NumericFormat
                                                                decimalScale={2}
                                                                value={
                                                                    (data.channel.audio.stats.revenue?.count || 0) /
                                                                    (data.channel.audio.stats.cost?.count || 1) // Avoid division by zero
                                                                }
                                                                displayType="text"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        :'')}

                                        {/* Impressions */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Impressions"
                                                explain={appVars.tooltips.impression}
                                                data={data.channel.audio.stats.impressions}
                                                previousdata={data.previousChannel.audio?.stats.impressions || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.audio.stats.impressions.count,
                                                    data.previousChannel.audio?.stats.impressions?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Clicks */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Clicks"
                                                explain={appVars.tooltips.click}
                                                data={data.channel.audio.stats.clicks}
                                                previousdata={data.previousChannel.audio?.stats.clicks || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.audio.stats.clicks.count,
                                                    data.previousChannel.audio?.stats.clicks?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* CTR */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="CTR"
                                                explain={appVars.tooltips.ctr}
                                                data={data.channel.audio.stats.ctr}
                                                previousdata={data.previousChannel.audio?.stats.ctr || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.audio.stats.ctr.count,
                                                    data.previousChannel.audio?.stats.ctr?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* CPM */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="CPM"
                                                explain={appVars.tooltips.cpm}
                                                data={data.channel.audio.stats.cpm}
                                                previousdata={data.previousChannel.audio?.stats.cpm || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.audio.stats.cpm.count,
                                                    data.previousChannel.audio?.stats.cpm?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} alignContent="space-between">
                                        {/* Conversions */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Conversions"
                                                explain={appVars.tooltips.conversion}
                                                data={data.channel.audio.stats.conversions}
                                                previousdata={data.previousChannel.audio?.stats.conversions || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.audio.stats.conversions.count,
                                                    data.previousChannel.audio?.stats.conversions?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Revenue */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Revenue"
                                                explain={appVars.tooltips.revenue}
                                                data={data.channel.audio.stats.revenue}
                                                previousdata={data.previousChannel.audio?.stats.revenue || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.audio.stats.revenue.count,
                                                    data.previousChannel.audio?.stats.revenue?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Conversion Rate */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Conversion Rate"
                                                explain={appVars.tooltips.conv_rate}
                                                data={data.channel.audio.stats.convrate}
                                                previousdata={data.previousChannel.audio?.stats.convrate || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.audio.stats.convrate.count,
                                                    data.previousChannel.audio?.stats.convrate?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>

                                        {/* Avg Gift */}
                                        <Grid item xl={3} sm={3} xs={12}>
                                            <ChartCard
                                                title="Avg Gift"
                                                explain={appVars.tooltips.avg_gift}
                                                data={data.channel.audio.stats.avgGift}
                                                previousdata={data.previousChannel.audio?.stats.avgGift || { count: 0 }}
                                                delta={calculatePercentageChange(
                                                    data.channel.audio.stats.avgGift.count,
                                                    data.previousChannel.audio?.stats.avgGift?.count || 0
                                                )}
                                                client={props.client}
                                                color={props.themeColor}
                                                view="stackadapt"
                                            />
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={2} alignContent="space-between">

                                        <Grid item sm={12} xs={12} >
                                            {(campaignData ?
                                                <MaterialTable
                                                    options={{

                                                        cellStyle: {
                                                            border: '1px solid ' + lightenHexColor(props.themeColor.primary, 0), textAlign: 'center',
                                                            padding: theme.spacing(1)
                                                        },
                                                        headerStyle: headerStyleObj,
                                                        grouping: false,
                                                        searchFieldVariant: "outlined",
                                                        exportButton: true,
                                                        exportAllData: true,
                                                        search: true,
                                                        paging: (campaignData.native.campaigns.length > 10 ? true : false),
                                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                        pageSizeOptions: [10, 25, 50, 100],
                                                        showTitle: false,
                                                        toolbar: true,
                                                        sort: true
                                                    }}
                                                    title="Campaigns"
                                                    data={campaignData.audio.campaigns}
                                                    count={(campaignData.audio.campaigns.length > 10 ? 10 : campaignData.audio.campaigns.length)}
                                                    client={props.client}
                                                    themeColor={props.themeColor}
                                                    columns={[
                                                        { title: "Campaign", cellStyle: { width: '100%', textAlign: 'left', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "name", render: rowdata => <Typography>{rowdata.name}</Typography> },
                                                        {
                                                            title: 'Impressions',
                                                            cellStyle: {
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            field: 'IMPRESSIONS', render: (rowdata) => <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.IMPRESSIONS} displayType="text" />
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Clicks',
                                                            field: 'CLICKS',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={0} thousandSeparator="," value={rowdata.CLICKS} displayType="text" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 30),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'CTR',
                                                            field: 'CTR',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={2} value={rowdata.CTR} displayType="text" suffix="%" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Conversions',
                                                            field: 'CONVERSIONS',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat thousandSeparator="," decimalScale={0} value={rowdata.CONVERSIONS} displayType="text" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            cellStyle: {
                                                                backgroundColor: lightenHexColor(props.themeColor.secondary, 20),
                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            },
                                                            title: 'Conv %',
                                                            field: 'CONV_RATE',
                                                            render: (rowdata) => (
                                                                <div style={{ display: 'flex' }}>
                                                                    <Box p={0} m={0} style={{ flex: '50%' }}>
                                                                        <NumericFormat decimalScale={2} value={rowdata.CONV_RATE} displayType="text" suffix="%" />
                                                                    </Box>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            title: 'Revenue',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'REVENUE', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.REVENUE} decimalScale={2} displayType="text" prefix="$" />
                                                        },
                                                        {
                                                            title: 'Avg. Gift',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'avgGift', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.avgGift} displayType="text" prefix="$" />
                                                        },
                                                        {
                                                            title: 'CPC',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'CPC', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPC} displayType="text" decimalScale={2} prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'CPM',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'CPM', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.CPM} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'Cost',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'COST', type: 'currency', render: (rowdata) => <NumericFormat thousandSeparator="," value={rowdata.COST} decimalScale={2} displayType="text" prefix="$" />, hidden: admintoken.token >= 8 ? false : true
                                                        },
                                                        {
                                                            title: 'ROAS',
                                                            cellStyle: {

                                                                textAlign: 'center',
                                                                padding: theme.spacing(0),
                                                                border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                            }, field: 'ROAS', type: 'number', hidden: admintoken.token >= 8 ? false : true, render: (rowdata) => <NumericFormat thousandSeparator="," decimalScale={2} value={rowdata.ROAS} displayType="text" />
                                                        }
                                                    ]}
                                                    detailPanel={[
                                                        {
                                                            tooltip: 'Show Creative',
                                                            icon: () => <FaImages color={props.themeColor.secondary} size={20} />,
                                                            openIcon: () => <FaWindowClose color={props.themeColor.secondary} size={20} />,
                                                            render: rowData => (
                                                                <div style={{ marginLeft: combinedCellWidth }}>

                                                                    <TableCard
                                                                        title="Images"
                                                                        data={query =>

                                                                            new Promise(async (resolve, reject) => {

                                                                                var myHeaders = new Headers();
                                                                                myHeaders.append("Content-Type", "application/json");
                                                                                var raw = JSON.stringify({
                                                                                    "campaign": rowData.id,
                                                                                    "cid": props.client.clientid,
                                                                                    "sdate": props.sDate,
                                                                                    "edate": props.eDate
                                                                                });

                                                                                var requestOptions = {
                                                                                    method: 'POST',
                                                                                    headers: myHeaders,
                                                                                    body: raw,
                                                                                    redirect: 'follow'
                                                                                };
                                                                                const res = await fetch(appVars.api_url + "/stackadapt/creatives/", requestOptions);
                                                                                const creatives = await res.json();
                                                                                // console.log(creatives);

                                                                                resolve({
                                                                                    data: creatives,
                                                                                    page: 0,
                                                                                    totalCount: creatives.length
                                                                                });

                                                                                setLoading(false);
                                                                            })

                                                                        }
                                                                        client={props.client}
                                                                        themeColor={props.themeColor}
                                                                        options={detailsOptions}
                                                                        columns={[
                                                                            { title: "", field: "size", cellStyle: { width2: 'auto', backgroundColor: lightenHexColor("#ececec", 0), }, render: rowdata => <Typography style={{ minWidth: (admintoken.token >= 8 ? 500 : 815), textAlign: 'left' }}></Typography> },

                                                                            {
                                                                                title: "", cellStyle: { width: 'auto', fontSize: fontSize, padding: theme.spacing(1), margin: 0 }, field: "name", render: rowdata => (
                                                                                    <div>
                                                                                        <audio className={classes.audio} controls preload="auto">
                                                                                            <source src={rowdata.name} type="audio/mp3" />
                                                                                            Your browser does not support the audio tag.
                                                                                        </audio>
                                                                                        <Typography variant='body2' color="textSecondary" align='center'>{rowdata.size}</Typography>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        ]}
                                                                    />

                                                                </div>
                                                            ),
                                                        },
                                                    ]}
                                                />
                                                : <div ><Typography style={{ color: props.color }} variant='h6' align='center'>Loading Campaign Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(3) }}></LinearProgress></div>)}


                                        </Grid>



                                    </Grid>
                                </div>
                                : <Typography style={{ margin: theme.spacing(2) }} align="center" gutterBottom>No Audio Data</Typography>)}

                        </TabPanel>

                        <TabPanel value={value} index={4} id="notes">
                            <Comments eDate={props.eDate} sDate={props.sDate} themeColor={props.themeColor} admintoken={admintoken} view={(admintoken.token >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59")} type="stackadapt"></Comments>

                        </TabPanel>

                    </div>
                    : <div style={{ padding: theme.spacing(8) }} ><Typography color='secondary' variant='h6' align='center'>Loading {props.title} Data</Typography><LinearProgress color='secondary' style={{ margin: theme.spacing(8) }}></LinearProgress></div>)}


            </ThemeProvider>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <CardHeader style={{ height: 50, background: props.themeColor.secondary }} title={
                    <IconButton className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>} />
                {videoUrl && (
                    <video

                        autoPlay
                        controls
                        //  poster={'https://i0.wp.com/douglasgreen.com/wp-content/uploads/2014/03/video-play-btn-featured.png?w=1920&ssl=1'}
                        muted
                        preload="auto"
                    >
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </Dialog>



        </div>
    );
};

StackAdapt.propTypes = {

};

export default StackAdapt;
