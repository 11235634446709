import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import appVars from '../../../appVars.json';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, LinearProgress, Box, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';

const useStyles = makeStyles({
    groupLabel: {
        backgroundColor: '#5791a0',
        color: 'white',
        padding: '5px 10px',
        fontWeight: 'bold',
        fontSize: '1rem',
    },
});

const ClientSearch = (props) => {
    const [domains, setDomains] = useState();
    const classes = useStyles();

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/customers");
        res
            .json()
            .then((res) => {
                setDomains(res);
            })
            .catch((err) => console.log(err.msg));
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box>
            {domains ? (
                <Autocomplete
                    id="combo-box-demo"
                    groupBy={(option) => option.clientname}
                    options={domains.sort((a, b) => -b.clientname.localeCompare(a.clientname))}
                    getOptionLabel={(option) => option.name}
                    style={{ minWidth: 350 }}
                    fullWidth
                    onChange={(event, newValue) => props.updateClient(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Domain Search...."
                            size="small"
                            fullWidth
                            variant="outlined"
                        />
                    )}
                    classes={{
                        groupLabel: classes.groupLabel, // Apply custom styles to the group labels
                    }}
                    renderOption={(option) => (
                        <Box display="flex" alignItems="center">
                            <ListItemIcon>
                                <Avatar
                                    variant="square"
                                    alt={option.name}
                                    src={`${appVars.api_url}/imageFiles/${option.id}.png`}
                                />
                            </ListItemIcon>
                            <ListItemText primary={option.name} />
                        </Box>
                    )}
                    {...props}
                />
            ) : (
                <LinearProgress />
            )}
        </Box>
    );
};

ClientSearch.propTypes = {};

export default ClientSearch;
