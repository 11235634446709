import React, { useState, useEffect, useRef } from 'react';
import {
    AppBar, Button, Chip, Grid, Typography,
    LinearProgress, Toolbar, Tabs, Tab, Box, TextField,
    ButtonGroup,
    IconButton
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import ChartCard from '../../../chart-card';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import Autocomplete from '@material-ui/lab/Autocomplete';
import appVars from '../../../../appVars.json';
import { lightenHexColor, generatePDF } from '../../../../dwi-functions';
import moment from 'moment';
import Comments from '../../../../system/control/comments';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MdPictureAsPdf } from "react-icons/md";
import { FcPrint } from 'react-icons/fc';

const fontSize = 'initial';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Google = (props) => {
    const [value, setValue] = useState(0);
    const [topConversionData, setTopConversionData] = useState([]);
    const [adNetworkData, setAdNetworkData] = useState([{ CAMPAIGN_TYPE: "SEARCH" }, { CAMPAIGN_TYPE: "YOUTUBE" }]);
    const [pmax, setPmax] = useState(false);
    const [searchData, setSearchData] = useState();
    const [keywordData, setKeywordData] = useState();
    const [regionsData, setRegionsData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [data, setData] = useState();
    const [dataType, setDataType] = useState([{ CAMPAIGN_TYPE: "SEARCH" }]);
    const [loading, setLoading] = useState(true);
    const [admintoken, setAdmin] = useState(props.admintoken);
    const reportRef1 = useRef()
    const reportRef2 = useRef();
    const pDate = new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.sDate)) + "-" + new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.eDate));

    const theme = createTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 31:
                setDataType([{ CAMPAIGN_TYPE: "PERFORMANCE_MAX" }]);
                fetchReportData([{ CAMPAIGN_TYPE: "PERFORMANCE_MAX" }]);
                break;
            case 1:
                fetchSearchData();
                break;
            default:
                fetchReportData(dataType);
                break;
        }
    };

    //console.log('Cost:',props.client.showCost);

    const fetchReportData = async (type) => {
        setLoading(true);
        const requestOptions = getRequestOptions(type);

        try {
            const [res4, campTable, keywordTable, regionTable] = await Promise.all([
                fetch(appVars.api_url + "/google/paid/data", requestOptions),
                fetch(appVars.api_url + "/google/paid/campaigns", requestOptions),
                fetch(appVars.api_url + "/google/paid/keywords", requestOptions),
                fetch(appVars.api_url + "/google/paid/regions", requestOptions)
            ]);

            const [res4Json, campTableJson, keywordTableJson, regionTableJson] = await Promise.all([
                res4.json(), campTable.json(), keywordTable.json(), regionTable.json()
            ]);
            setLoading(false);
            setData(res4Json[0]);
            setAdNetworkData(res4Json[1]);
            setCampaignData(campTableJson);
            setKeywordData(keywordTableJson);
            setRegionsData(regionTableJson);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSearchData = async () => {
        setLoading(true);
        const requestOptions = getRequestOptions(dataType);

        try {
            const searchTable = await fetch(appVars.api_url + "/google/paid/search", requestOptions);
            const searchTableJson = await searchTable.json();
            setSearchData(searchTableJson);
            setTopConversionData(searchTableJson);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getRequestOptions = (type) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "aid": props.advertiser_id,
            "sdate": props.sDate,
            "edate": props.eDate,
            "account": props.which,
            "type": type,
            "token": props.token
        });

        return {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
    };

    useEffect(() => {
        fetchReportData(dataType);
    }, [props, dataType]);

    return (
        <div>

            {!loading && data ? (

                <div>
                    <AppBar variant="outlined" color='secondary' position="relative" style={{ margin: theme.spacing(0) }}>
                        <Grid container alignItems='center'>
                            <Grid item sm={8}>
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto">
                                    <Tab label={props.title} {...a11yProps(0)} />
                                    <Tab label="Search" {...a11yProps(1)} />
                                    {pmax && <Tab label='PMAX' {...a11yProps(2)} />}
                                    <Tab label="Notes" {...a11yProps(3)} />
                                </Tabs>
                            </Grid>
                            <Grid item sm={2} justifyContent="flex-end" >

                            </Grid>
                            {(admintoken.token >= 6 || props.client.showCost === true ?
                                <>


                                    <Grid item sm={1}>
                                        <Box p={1} bgcolor={lightenHexColor(props.themeColor.secondary, 40)} mr={1} border={1} borderColor={lightenHexColor(props.themeColor.secondary, 10)} ><Typography color="textPrimary">Cost: <NumericFormat decimalScale={2} value={data.cost.count} thousandSeparator="," displayType="text" prefix="$" /></Typography></Box>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'initial' }}>
                                        <Box p={1} bgcolor={lightenHexColor(props.themeColor.secondary, 40)} mr={1} border={1} borderColor={lightenHexColor(props.themeColor.secondary, 10)} > <Typography color="textPrimary">ROAS: <NumericFormat decimalScale={2} value={(data.revenue.count / data.cost.count)} displayType="text" /></Typography></Box>
                                    </Grid>
                                </>
                            :'')}

                        </Grid>
                    </AppBar>

                    <TabPanel value={value} index={0}  >
                        <Box pt={1} pb={1} bgcolor={lightenHexColor("#000000", 90)} >
                            <ButtonGroup size="medium">
                                <Button variant="text" title='Print Report'
                                    onClick={() => generatePDF(reportRef1, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                ><FcPrint size={20} />
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <Grid style={{ padding: theme.spacing(1) }} container spacing={2} alignContent="space-between">
                            <Grid item xl={3} sm={3} xs={12}>
                                {props.which !== 'Grant' && (
                                    <Toolbar style={{ padding: 0 }}>
                                        <Autocomplete
                                            options={adNetworkData}
                                            value={dataType}
                                            multiple
                                            color="secondary"
                                            fullWidth
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        label={option.CAMPAIGN_TYPE}
                                                        {...getTagProps({ index })}
                                                        style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }}
                                                    />
                                                ))
                                            }
                                            getOptionLabel={(option) => option.CAMPAIGN_TYPE}
                                            renderInput={(params) => <TextField {...params} label="Choose an Ad Network" variant="outlined" />}
                                            onChange={(e, newValue) => {
                                                e.stopPropagation();
                                                setDataType(newValue.length > 0 ? newValue : [{ CAMPAIGN_TYPE: "SEARCH" }]);
                                                fetchReportData(newValue);
                                            }}
                                        />

                                    </Toolbar>
                                )}
                            </Grid>

                        </Grid>
                        <div ref={reportRef1} >
                            <Grid container spacing={2} style={{ padding: theme.spacing(1) }} >
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Impressions" explain={appVars.tooltips.impression} data={data.impressions} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Clicks" data={data.clicks} explain={appVars.tooltips.click} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="CTR" data={data.ctr} explain={appVars.tooltips.ctr} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="CPC" data={data.cpc} explain={appVars.tooltips.cpc} client={props.client} color={props.themeColor} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ padding: theme.spacing(1) }} >
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Conversions" data={data.conversions} explain={appVars.tooltips.conversion} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Revenue" data={data.revenue} explain={appVars.tooltips.revenue} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Conversion Rate" data={data.convrate} explain={appVars.tooltips.conv_rate} client={props.client} color={props.themeColor} />
                                </Grid>
                                <Grid item xl={3} sm={3} xs={12}>
                                    <ChartCard title="Avg Gift" data={data.avgGift} explain={appVars.tooltips.avg_gift} client={props.client} color={props.themeColor} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ padding: theme.spacing(1) }}>
                                <Grid item sm={12} xs={12}>
                                    {campaignData ? (
                                        <TableCard
                                            admintoken={admintoken}
                                            options={{
                                                grouping: false,
                                                searchFieldVariant: "outlined",
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                paging: campaignData.length > 10,
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            title="Campaigns"
                                            data={campaignData}
                                            count={campaignData.length > 10 ? 10 : campaignData.length}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            columns={[
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor("#cecece", 0),
                                                        textAlign: 'left',
                                                        minWidth: 300,
                                                        fontSize: fontSize,
                                                        padding: theme.spacing(.5),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }, title: "Campaign Name", field: "CAMPAIGN", render: rowdata => <Typography align='left' style={{ fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.CAMPAIGN}</Typography>
                                                }
                                            ]}
                                        />
                                    ) : (
                                        <div>
                                            <Typography color="secondary" variant='h6' align='center'>Loading Campaign Data</Typography>
                                            <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }} />
                                        </div>
                                    )}
                                </Grid>

                                <Grid item sm={12} xs={12}>
                                    {keywordData ? (
                                        <TableCard
                                            admintoken={admintoken}
                                            title="Keyword"
                                            data={keywordData}
                                            count={keywordData.length > 5 ? 5 : keywordData.length}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            options={{
                                                grouping: false,
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                searchFieldVariant: "outlined",
                                                paging: keywordData.length > 5,
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor("#cecece", 0),
                                                        textAlign: 'left',
                                                        minWidth: 300,
                                                        fontSize: fontSize,
                                                        padding: theme.spacing(.5),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }
                                                    , title: "Keyword", field: "KEYWORD", render: rowdata => <Typography align='left' style={{ fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.KEYWORD}</Typography>
                                                }
                                            ]}
                                        />
                                    ) : (
                                        <div>
                                            <Typography color="secondary" variant='h6' align='center'>Loading Keyword Data</Typography>
                                            <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }} />
                                        </div>
                                    )}
                                </Grid>

                                <Grid item sm={12} xs={12}>
                                    {regionsData ? (
                                        <TableCard
                                            title="Regions"
                                            data={regionsData}
                                            count={regionsData.length > 15 ? 15 : regionsData.length}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            options={{
                                                grouping: false,
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                searchFieldVariant: "outlined",
                                                paging: regionsData.length > 5,
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                {
                                                    cellStyle: {
                                                        backgroundColor: lightenHexColor("#cecece", 0),
                                                        textAlign: 'left',
                                                        minWidth: 300,
                                                        fontSize: fontSize,
                                                        padding: theme.spacing(.5),
                                                        border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    }
                                                    , title: "ZIP/CITY", field: "REGION", render: rowdata => <Typography align='left' style={{ fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.REGION.split(',')[0]}</Typography>
                                                }
                                            ]}
                                        />
                                    ) : (
                                        <div>
                                            <Typography color="secondary" variant='h6' align='center'>Loading Regions Data</Typography>
                                            <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }} />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={1} data-title="Search Data">
                    <Box pt={1} pb={1} bgcolor={lightenHexColor("#000000", 90)}  >
                            <ButtonGroup size="small">
                                <IconButton
                                    onClick={() => generatePDF(reportRef2, props.title, pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                                ><FcPrint />
                                </IconButton>
                            </ButtonGroup>
                        </Box>
                        <div ref={reportRef2} >
                            <Grid container spacing={2} alignContent="space-between" style={{ padding: theme.spacing(1) }}>
                                <Grid item sm={12} xs={12}>
                                    {searchData ? (
                                        <TableCard
                                            title="Search"
                                            data={searchData}
                                            count={searchData.length > 10 ? 10 : searchData.length}
                                            client={props.client}
                                            themeColor={props.themeColor}
                                            options={{
                                                grouping: false,
                                                exportButton: true,
                                                exportAllData: true,
                                                search: true,
                                                searchFieldVariant: "outlined",
                                                paging: searchData.length > 10,
                                                exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                                pageSizeOptions: [10, 25, 50, 100],
                                                showTitle: false,
                                                toolbar: true,
                                                sort: true
                                            }}
                                            columns={[
                                                { title: "Search Term", field: "SEARCH", render: rowdata => <Typography align='left' style={{ fontSize: fontSize, padding: 0, margin: 0 }}>{rowdata.SEARCH}</Typography> }
                                            ]}
                                        />
                                    ) : (
                                        <div>
                                            <Typography color="secondary" variant='h6' align='center'>Loading Search Data</Typography>
                                            <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }} />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </TabPanel>

                    <TabPanel value={value} index={2} data-title="Notes">
                        <Comments
                            eDate={props.eDate}
                            sDate={props.sDate}
                            themeColor={props.themeColor}
                            admintoken={admintoken}
                            view={admintoken >= 6 ? "5af0a295-8b57-4ccb-8cc8-d54e5bb278e6" : "a7edd5c0-7a77-49ed-86ad-6fe3066a0f59"}
                            type={'google_' + props.which.toLowerCase()} />
                    </TabPanel>
                </div>
            ) : (
                <div style={{ paddingTop: theme.spacing(8) }}>
                    <Typography color="secondary" variant='h6' align='center'>Loading {props.title} Data</Typography>
                    <LinearProgress color='secondary' style={{ margin: theme.spacing(3) }} />
                </div>
            )
            }
        </div >
    );
};

export default Google;
