import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import LightBoxSettings from '../LightCard/LightBoxSettings';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add'
import LightGrid from '../LightGrid';
import Fab from '@material-ui/core/Fab';


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    head: {
        borderBottom: "1px solid"
    },
    appBar: {
      position: 'relative',
      backgroundColor: theme.palette.primary.main
    },
    paper: {
      padding: theme.spacing(3)
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    container: {
      padding: '1em',
    },
    media: {
      height: 145,
      backgroundPosition: 'top center'
    },
    menu_header: {
      padding: '5px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText
    },
    h5: {
      padding: '15px',
      color: theme.palette.secondary.light
    },
    button: {
      borderStyle: 'solid',
      border: '1px',
      borderColor: '#fff',
      color: '#fff',
      marginRight: "7px"
    },
    image: {
      maxWidth: '70%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    drop: {
      width: '50%',
      marginLeft: '0',
      marginRight: 'auto',
      marginBottom: theme.spacing(3)
    },
    body:{
      background: "azure"
    }
  
  }));

const LightBoxes = (props) => {
    const classes = useStyles();
    const [openNewLBE, setOpenNewLBE] = useState(false);

  
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
      });
  

  
    const handleClose = () => {
        setOpenNewLBE(false);
    };
  
    const handleClickOpenLBE = () => {
        setOpenNewLBE(true);
    };
  



    return (
        <div className={classes.root}>

            <Typography variant="h4" color="textSecondary" gutterBottom className={classes.head} >
                LightBoxes
            </Typography>

                <LightGrid {...props} ></LightGrid>

        
          
        </div>
    );
};

LightBoxes.propTypes = {

};

export default LightBoxes;
