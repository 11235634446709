import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, ButtonGroup, Grid, LinearProgress, Typography, useMediaQuery } from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import TableCard from '../../../table-card';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { lightenHexColor, darkenHexColor, generatePDF } from '../../../../dwi-functions';
import appVars from '../../../../appVars.json';
import _ from 'lodash';
import { FcPrint } from 'react-icons/fc';






const Overview = (props) => {

    const [campaignGP, setcampaignGP] = useState();
    const [overview, setOverview] = useState([]);
    const [allOverview, setallOverview] = useState([]);
    const admintoken = props.admintoken;
    const pDate = new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.sdate)) + "-" + new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.edate));

   // console.log(props.client);
    const fontSize = '13px';
    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary, // Default primary color
            },
            secondary: {
                main: props.themeColor.secondary, // Default primary color
            },
        }
    });
    const reportRef = useRef();





    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            //  maxWidth: 1600,
            margin: 'auto'
        },
        data: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        content: {
            marginTop: 0,
            [theme.breakpoints.up('sm')]: {
                marginTop: 0,
            },

        },
        boxes: {
            padding: theme.spacing(1),
            flexGrow: 1,
            flex: '100%',
            fontSize: '1.25rem',
            textTransform: 'uppercase',

        },
        boxContain: {
            padding: theme.spacing(0),
            background: "linear-gradient(135deg, " + lightenHexColor(props.themeColor.secondary, 10) + " 0%, #fff 50%)",
            border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 20),
            borderRadius: theme.shape.borderRadius,
            boxShadow: "1px 0px 1px 0px " + props.themeColor.secondary
        }
    }));


    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    const headerStyleObj = {
        textAlign: 'center',
        backgroundColor: lightenHexColor(props.themeColor.secondary, 0),
        color: lightenHexColor(props.themeColor.primary, 0),
        fontSize: fontSize,
        border: '1px solid ' + props.themeColor.secondary,
        paddingTop: 5,
        paddingBottom: 5,
        maxWidth: 75,
    };


    // Function to sum fields across all objects in the array
    const sumFields = (data) => {
        return data.reduce((acc, obj) => {
            // List of keys to sum
            const keysToSum = ['IMPRESSIONS', 'CLICKS', 'CONVERSIONS', 'REVENUE', 'COST'];

            keysToSum.forEach(key => {
                // Initialize the accumulator for the key if it doesn't exist
                if (!acc[key]) acc[key] = 0;
                // Sum the current object's value for the key to the accumulator
                acc[key] += obj[key];
            });

            return acc;
        }, {});
    };


    const removeZeroObjects = (data) => {
        return _.filter(data, obj => {
            // List of keys to check for non-zero values
            const keysToCheck = ['IMPRESSIONS', 'CLICKS', 'CONVERSIONS', 'REVENUE', 'COST'];

            // Check if at least one of the specified fields has a non-zero value
            return _.some(keysToCheck, key => obj[key] !== 0);
        });
    };



    async function fetchData() {

        setcampaignGP();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": props.clientid,
            "sdate": props.sdate,
            "edate": props.edate,
            "account": "Paid",
            "type": [{ CAMPAIGN_TYPE: "SEARCH" }],
            "paidtoken": props.paidtoken,
            "granttoken": props.granttoken
        });


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        var k = await fetch(appVars.api_url + "/platform-overview/get", requestOptions)
        k
            .json()
            .then((response) => {
                setcampaignGP(response);
                var c = removeZeroObjects(response);
                //f.push();
                //f.push(response[0]);
                setallOverview(sumFields(c));
                setOverview(c);
            })
            // .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }



    // console.log('Desktop:', isDesktop)

    useEffect(() => {

        fetchData();



        return () => {
            //        cleanup

        };
        // eslint-disable-next-line
    }, [props]);




    return (
        <ThemeProvider theme={theme}>
            <div style={{ padding: theme.spacing(0) }}>

                <Box pt={1} pb={1} bgcolor={lightenHexColor(props.themeColor.secondary, 30)} mb={0} >
                    <ButtonGroup size="medium">
                        <Button variant="text" title='Print Report'
                            onClick={() => generatePDF(reportRef, 'Overview', pDate, appVars.api_url + '/imageFiles/' + props.idi + '.png')}
                        ><FcPrint size={20} />
                        </Button>
                    </ButtonGroup>
                </Box>

                {(campaignGP ?


                    <div style={{ padding: theme.spacing(1) }}>

                        <Grid ref={reportRef} container spacing={1}  >

                            <Grid item sm={2} xs={12}>
                                <Box className={classes.boxContain}>
                                    <Typography align='center' className={classes.boxes}>IMPRESSIONS</Typography>
                                    <Typography variant='h6' align='center' style={{ color: darkenHexColor(props.themeColor.primary, 60) }} >
                                        <NumericFormat displayType='text' decimalScale={2} thousandSeparator="," value={allOverview['IMPRESSIONS']} ></NumericFormat>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={2} xs={12}>
                                <Box className={classes.boxContain}>
                                    <Typography align='center' className={classes.boxes}>CLICKS</Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Box p={0} m={0} style={{ flex: '50%', borderRight: '1px solid' + lightenHexColor(props.themeColor.secondary, 0) }}  >
                                            <Typography variant='h6' align='center' style={{ margin: 0, color: darkenHexColor(props.themeColor.primary, 60) }} >
                                                <NumericFormat displayType='text' decimalScale={2} thousandSeparator="," value={allOverview['CLICKS']} ></NumericFormat>
                                            </Typography>
                                        </Box>
                                        <Box p={0} m={0} style={{ flex: '50%' }}  >
                                            <Typography variant='h6' align='center' style={{ color: darkenHexColor(props.themeColor.primary, 60) }} >
                                                <NumericFormat displayType="text" decimalScale={2} suffix='%' value={allOverview['CLICKS'] / allOverview['IMPRESSIONS'] * 100}></NumericFormat>
                                            </Typography>
                                        </Box>
                                    </div>
                                </Box>
                            </Grid>

                            <Grid item sm={2} xs={12}>
                                <Box className={classes.boxContain}>
                                    <Typography align='center' className={classes.boxes}>Conversions</Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Box p={0} m={0} style={{ flex: '50%', borderRight: '1px solid' + lightenHexColor(props.themeColor.secondary, 0) }}  >
                                            <Typography variant='h6' align='center' style={{ margin: 0, color: darkenHexColor(props.themeColor.primary, 60) }} >
                                                <NumericFormat displayType='text' decimalScale={2} thousandSeparator="," value={allOverview['CONVERSIONS']} ></NumericFormat>
                                            </Typography>
                                        </Box>
                                        <Box p={0} m={0} style={{ flex: '50%' }}  >
                                            <Typography variant='h6' align='center' style={{ color: darkenHexColor(props.themeColor.primary, 60) }} >
                                                <NumericFormat displayType="text" decimalScale={2} suffix='%' value={allOverview['CONVERSIONS'] / allOverview['CLICKS'] * 100}></NumericFormat>
                                            </Typography>
                                        </Box>
                                    </div>
                                </Box>
                            </Grid>

                            <Grid item sm={2} xs={12}>
                                <Box className={classes.boxContain}>
                                    <Typography align='center' className={classes.boxes}>REVENUE</Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Box p={0} m={0} style={{ flex: '50%', borderRight: '1px solid' + lightenHexColor(props.themeColor.secondary, 0) }}  >
                                            <Typography variant='h6' align='center' style={{ margin: 0, color: darkenHexColor(props.themeColor.primary, 60) }} >
                                                <NumericFormat prefix='$' displayType='text' decimalScale={2} thousandSeparator="," value={allOverview['REVENUE']} ></NumericFormat>
                                            </Typography>
                                        </Box>
                                        <Box p={0} m={0} style={{ flex: '50%' }}  >
                                            <Typography variant='h6' align='center' style={{ color: darkenHexColor(props.themeColor.primary, 60) }} >
                                                <NumericFormat displayType="text" decimalScale={2} prefix='$' value={(allOverview['REVENUE'] / allOverview['CONVERSIONS']).toFixed(2)}></NumericFormat>
                                            </Typography>
                                        </Box>
                                    </div>
                                </Box>
                            </Grid>

                              {(admintoken.token >= 6 || props.client.showCost === true ?
                                <Grid item sm={2} xs={12}>
                                    <Box className={classes.boxContain}>
                                        <Typography align='center' className={classes.boxes}>COST</Typography>
                                        <Typography variant='h6' align='center' style={{ color: darkenHexColor(props.themeColor.primary, 60) }}>
                                            <NumericFormat displayType="text" decimalScale={2} thousandSeparator="," prefix='$' value={allOverview['COST']} ></NumericFormat>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                null)}

                            {(admintoken.token >= 6 || props.client.showCost === true ?
                                <Grid item sm={2} xs={12}>
                                    <Box className={classes.boxContain}>
                                        <Typography align='center' className={classes.boxes}>ROAS</Typography>
                                        <Typography variant='h6' align='center' style={{ color: darkenHexColor(props.themeColor.primary, 60) }}>
                                            <NumericFormat displayType="text" decimalScale={2} thousandSeparator="," value={allOverview['REVENUE'] / allOverview['COST']} ></NumericFormat>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                null)}


                            <Grid item xs={12}>
                                <div style={{ marginTop: theme.spacing(2), display: (!isDesktop ? 'none' : 'initial') }}>
                                    <TableCard
                                        title=""
                                        data={overview}
                                        count={10}
                                        options={{
                                            grouping: false,
                                            headerStyle: headerStyleObj,
                                            searchFieldVariant: "standard",
                                            exportButton: true,
                                            exportAllData: true,
                                            search: false,
                                            paging: false,
                                            showTitle: false,
                                            toolbar: false,
                                            sort: true,
                                        }}
                                        themeColor={props.themeColor}
                                        columns={[
                                            {
                                                cellStyle:
                                                {
                                                    border: '1px solid ' + lightenHexColor(props.themeColor.secondary, 0),
                                                    textAlign: 'center',
                                                    padding: theme.spacing(2)
                                                },
                                                title: "Platform", field: "name", render: rowdata => <Typography style={{ fontSize: fontSize, textAlign: 'left', padding: 0, margin: 0 }} align='left' >{rowdata.name}</Typography>
                                            }
                                        ]}

                                    />
                                </div>

                            </Grid>










                        </Grid>




                    </div>
                    : <div style={{ paddingTop: theme.spacing(5) }} ><Typography color="secondary" variant='h6' align='center'>Loading Overview Data</Typography><LinearProgress color="secondary" style={{ margin: theme.spacing(2) }}></LinearProgress></div>)}


            </div>
        </ThemeProvider>
    );
};

Overview.propTypes = {

};

export default Overview;
