import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Skeleton } from "@material-ui/lab";
import logo from '../../Digital-Dashboard-Logo-FINAL.png';
import { useMediaQuery, ButtonGroup, Typography, Tabs, Tab, Snackbar } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { setCookie, getCookie, lightenHexColor, darkenHexColor } from '../../dwi-functions';
import appVars from '../../appVars.json';
import _ from "lodash";
import { useLocation } from 'react-router-dom';
import ClipboardJS from 'clipboard';
import DateRangeSelector from "../../clients/controls/date-range-selector";
import { FcPrint, FcShare, FcAssistant } from "react-icons/fc";
import { BsGoogle, BsMeta, BsBing, BsDisplay, BsEnvelopeAt, BsCurrencyDollar, BsCardImage } from "react-icons/bs";

const drawerWidth = 200;


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}





// Utility function to get the first day of the previous month
function getFirstDayOfPreviousMonth() {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  return firstDay;
}

// Utility function to get the last day of the previous month
function getLastDayOfPreviousMonth() {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  return lastDay;
}



export default function MiniDrawer(props) {
  // eslint-disable-next-line



  const location = useLocation();  //console.log(props);
  var id = location.pathname.split("/")[2];
  const searchParams = new URLSearchParams(location.search);
  const sdate = searchParams.get('sdate');
  const edate = searchParams.get('edate');
  const tab = (searchParams.get('tab') ? Number(searchParams.get('tab')) : 0)
  const [snack, setSnack] = useState({ msg: "", open: false });

  var _d = sdate ? new Date(sdate) : getFirstDayOfPreviousMonth;
  var _e = edate ? new Date(edate) : getLastDayOfPreviousMonth;


  const [selectedDateS, setSelectedDateS] = useState(_d);
  const [selectedDateE, setSelectedDateE] = useState(_e);


  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(5.5) + 1,
      },
    },
    avatar: { float: 'right' },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      height: '100px',
      background: lightenHexColor(theme.palette.primary.main, 0),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,

      marginRight: theme.spacing(1),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    siteTitle: {
      width: "100%",

    },
    logoContain: {
      //maxWidth: "150px",
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center'
    },
    logos: {
      width: "auto",
      textAlign: 'center',
      background: lightenHexColor(theme.palette.primary.main, 0),
      padding: theme.spacing(.5),
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(0),
    },
    navLogo: {
      backgroundColor: theme.palette.secondary.main,
    },
    selected: {
      // Define your active tab color here
      background: darkenHexColor(props.brandColor.secondary, 0),
      color: "#fff",
      fontSize: '101%'
    },
    tab: {
      //fontSize: '18px',
      justifyContent: 'flex-start',
      flexDirection: 'row', // Aligns the icon and text horizontally
      '& > *:first-child': {
        marginRight: theme.spacing(3), // Adds spacing between the icon and text
        fontSize: '20px',
        marginBottom: '0px!important',
        minWidth: '20px'
      },
    }
  }));

  const classes = useStyles();
  const admintoken = props.admintoken.token;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const logOut = () => {
    setCookie('_bu', '', -1);
    setCookie('_buta', '', -1);
    setCookie('_buta_s', '', -1);
    sessionStorage.removeItem("_buta_s");
    sessionStorage.removeItem("_bu");
    sessionStorage.removeItem("_buta");
    window.location.href = "/";
  }


  var clipboard = new ClipboardJS('#urlCopy');

  clipboard.on('success', function (e) {
    setSnack({ msg: e.text + "  Copied!", open: true });
    e.clearSelection();
  });

  clipboard.on('error', function (e) {
    setSnack({ msg: e.action + "  Error!", open: true });
  });



  //console.log(props);
  setCookie('_au', 0, 7);

  const theme = createTheme({
    palette: {
      primary: {
        main: darkenHexColor(props.brandColor.primary, 0), // Assuming darkenHexColor returns a valid color
      },
      secondary: {
        main: darkenHexColor(props.brandColor.secondary, 0), // Assuming the same
      },
    },
    overrides: {
      MuiAppBar: {
        root: {
          height: '100px', // Set the height of the AppBar
          background: 'green'
        }
      },
      MuiOutlinedInput: {
        root: {
          '&$focused $notchedOutline': {
            borderColor: 'blue', // Change this to your desired color
          },
        },
        notchedOutline: {},
        focused: {},
      },
      MuiToolbar: {
        root: {
          minHeight: '100px',  // Set custom height for the Toolbar
          '@media (min-width:0px) and (orientation: landscape)': {
            minHeight: '70px'  // Set custom height for landscape mode
          },
          '@media (min-width:600px)': {
            minHeight: '100px'  // Set custom height for devices above 600px wide
          }
        }
      }
    },
    mixins: {
      toolbar: {
        minHeight: 100,  // Align the mixin minHeight with the Toolbar custom height
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: '70px'  // Custom height for landscape orientation
        },
        '@media (min-width:600px)': {
          minHeight: '100px'  // Custom height for wider screens
        }
      }
    }
  });
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  // console.log('desktop:', isDesktop);
  const [copyUrl, setcopyUrl] = useState(window.location.href + '?sdate=' + props.sDate + '&edate=' + props.eDate + '&tab=' + props.value);
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = useState(tab);
  const [owner, setOwner] = useState('donnie@digitalwerks.com');
  const [support, setSupport] = useState('donnie@digitalwerks.com');
  const [clientlogo,setClientLogo] = useState((props.profile.clientlogo===true ? appVars.api_url + "/imageFiles/" + props.profile.client + ".png": ''));
  //console.log(props.profile);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlesnackClose = (e) => {
    setSnack({ msg: "", open: false });
  }

  // eslint-disable-next-line
  const [order, setorder] = useState([
    { overview: 'Overview', logo: <AssessmentIcon /> },
    { google_paid: 'Google Paid', logo: <BsGoogle /> },
    { google_grant: 'Google Grant', logo: <BsGoogle /> },
    { bing: 'Bing', logo: <BsBing /> },
    { meta: 'Meta', logo: <BsMeta /> },
    { stackadapt: 'Display', logo: <BsDisplay /> },
    { virtuous: 'Email', logo: <BsEnvelopeAt /> },
    { donations: 'Donations', logo: <BsCurrencyDollar /> },
    { lightboxes: 'Lightboxes', logo: <BsCardImage/> }
  ]);




  const handleRangeSelect = (rangeKey) => {
    setSelectedDateS(rangeKey.startDate.toISOString().split('T')[0]);
    setSelectedDateE(rangeKey.endDate.toISOString().split('T')[0]);
    // console.log(window.location.href + '/?sdate=' + selectedDateS.toISOString().split('T')[0] + '&edate=' + selectedDateE.toISOString().split('T')[0] + '&tab=' + props.value)
    setcopyUrl(window.location.href + '?sdate=' + props.sDate + '&edate=' + props.eDate + '&tab=' + props.value);
    setOpen(!open);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };


  const toggle = () => setOpen(!open);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { window.location = "/account/" + getCookie('_bu'); }}>My account</MenuItem>
      {(admintoken >= 10 ? <MenuItem onClick={() => { window.location = "/control"; }}>Settings</MenuItem> : <Skeleton variant="rect" style={{ padding: theme.spacing(1) }} >Admin Access Needed</Skeleton>
      )}
      <MenuItem onClick={logOut}>Logout</MenuItem>


    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

    </Menu>
  );


  async function fetchData() {


    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    const res = await fetch(appVars.api_url + "/users/g/" +(props.client ? props.client.owner : id), requestOptions);
    res.json()
      .then((result) => {
        // console.log(result);
        setOwner(result[0].email)
      }
      )
      .catch((error) => console.error(error));

    const res2 = await fetch(appVars.api_url + "/users/g/" + (props.client ? props.client.owner : id), requestOptions)
    res2.json()
      .then((result) => setSupport(result[0].email))
      .catch((error) => console.error(error));

  }


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {


    fetchData();


    return () => {

    };
  }, [props]);



  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AppBar
          variant="outlined"
          position="fixed"
          color="primary"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.logoContain1}>
              <img style={{ width: '100%', maxWidth: 200 }} className={classes.logos} alt='' src={logo} />

            </div>
            <div className={classes.search}>
              <div style={{ flexGrow: 1, alignItems: 'center', display: 'flex' }} >
                <Typography variant="h6" color="secondary">  {(props.client ? props.client.name : '')}
                </Typography>
              </div>
            </div> <div className={classes.grow} />
            <div style={{ flexGrow: 1, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <img style={{ maxHeight: 85 }} className={classes.logos} alt='' src={(id !== undefined ? appVars.api_url + '/imageFiles/' + id + '.png' : 'https://place-hold.it/60x60/aaa/000000/b8b8b8.jpg&text=R-LOGO')} />

            </div>  <div className={classes.grow} />
            <div className={classes.search}>
              <Typography variant="h6" color="secondary">  {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.sDate))} - {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(props.eDate))}
              </Typography>
            </div>



            <div className={classes.search}>


              <ButtonGroup style={{ background: '#fff' }} color="secondary" variant="contained" size="small" fullWidth >


                <DateRangeSelector
                  icon={true}
                  open={open}
                  initialDateRange={{
                    startDate: _d,
                    endDate: new Date()
                  }}
                  sDate={selectedDateS}
                  eDate={selectedDateE}
                  toggle={toggle}
                  onChange={handleRangeSelect}
                  childToParent={props.childToParent}
                  showOutsideDays
                  fixedWeeks
                  themeColor={props.brandColor}
                />

                <IconButton size='small' onClick={(e) => e.preventDefault()} color="primary">
                  <FcShare id="urlCopy"
                    data-clipboard-text={copyUrl}
                  />
                </IconButton>
                {(props.admintoken.token >= 10 ?
                  <>
                    <IconButton size='small' onClick={(e) => e.preventDefault()} color="primary">
                      <FcPrint />
                    </IconButton>

                    <IconButton size='small' href={"mailto:" + owner + ";" + support + "?subject=Dashboard%20Help%20Needed&body=test"} color="primary">

                      <FcAssistant />
                    </IconButton></>
                  : '')}
              </ButtonGroup>


            </div>







            <div className={classes.sectionDesktop}>
              <img className={classes.logos} style={{ maxWidth: 85 }} alt='' src={clientlogo} />
            </div>



            
            <div className={classes.sectionMobile}>
              <ButtonGroup style={{ background: '#fff' }} color="secondary" variant="contained" size="small" fullWidth >

                <IconButton size='small' onClick={(e) => e.preventDefault()} color="primary">
                  <DateRangeSelector
                    icon={true}
                    open={open}
                    initialDateRange={{
                      startDate: _d,
                      endDate: new Date()
                    }}
                    sDate={selectedDateS}
                    eDate={selectedDateE}
                    toggle={toggle}
                    onChange={handleRangeSelect}
                    childToParent={props.childToParent}
                    showOutsideDays
                    fixedWeeks
                    themeColor={props.brandColor}
                  />
                </IconButton>
                <IconButton size='small' onClick={(e) => e.preventDefault()} color="primary">
                  <FcShare id="urlCopy"
                    data-clipboard-text={copyUrl}
                  />
                </IconButton>
                {(props.admintoken.token >= 10 ?
                  <>
                    <IconButton size='small' onClick={(e) => e.preventDefault()} color="primary">
                      <FcPrint />
                    </IconButton>

                    <IconButton size='small' href={"mailto:" + owner + ";" + support + "?subject=Dashboard%20Help%20Needed&body=test"} color="primary">
                      <FcAssistant />
                    </IconButton>
                  </> : '')}
              </ButtonGroup>
            </div>


          </Toolbar>

        </AppBar>
        {renderMobileMenu}
        {renderMenu}


        <Drawer
          style={{ background: theme.palette.secondary.light }}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <div className={classes.logoContain}>

            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />

          {(props.tabs.tabs && props.adsettings ?
            <div style={{ justifyContent: 'flex-start' }}>

              <Tabs indicatorColor="primary" style={{ width: '100%' }} className={classes.tabs} orientation='vertical' variant="standard" scrollButtons="auto" value={value} onChange={handleChange} aria-label="simple tabs example">
                {Object.entries(_.pickBy(props.adsettings, (value, key) => { return value.id !== ""; })).map((tab, index) => {
                  var foundObject = _.find(order, (obj) => _.has(obj, tab[0]));
                  // Get the value associated with the key, if the object is found
                  var foundValue = foundObject ? _.get(foundObject, tab[0]) : 'Key not found';
                  // console.log("Found:",foundObject);
                  var k = <Tab icon={foundObject.logo} onClick={(e) => { e.preventDefault(); props.setValue(index); }} key={index} classes={{ selected: classes.selected, wrapper: classes.tab }} style={{ justifyItems: 'flex-start', width: isDesktop ? '100%' : 'auto' }} label={foundValue} {...a11yProps(index)} />
                  //  var k = <Tab classes={{ selected: classes.selected }} style={{ justifyContent: 'flex-start', width: isDesktop ? '100%' : 'auto' }} label={foundValue}  />
                  return (
                    k
                  )
                })}

              </Tabs>
            </div>
            : <LinearProgress color="secondary"></LinearProgress>
          )}



        </Drawer>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlesnackClose}
            open={snack.open}
            color="success"
            autoHideDuration={3000}
            message={snack.msg}
          />
        </div>
      </ThemeProvider>
    </div>
  );
}
